import classNames from "classnames";
import React, { FC } from "react";
import { FaRegSquare } from "react-icons/fa6";
import Icon, { Icons } from "../../common/Icon";
import { translate } from "../../../libs/utils/lang.util";

type Props = {
  title: string;
  isSelected: boolean;
  onSelect: () => void;
};

const CategoryCard: FC<Props> = ({ title, isSelected, onSelect }) => {
  const containerClass = classNames(
    "flex",
    "gap-x-4",
    "w-full",
    "h-[70px]",
    "rounded-xl",
    "items-center",
    "justify-start",
    "cursor-pointer",
    "transition-all",
    "overflow-hidden relative",
    "shadow-button",
    "p-4",
    "bg-white"
  );
  return (
    <div onClick={onSelect} className={containerClass}>
      <Icon name={title.toLowerCase() as Icons} size={40} />
      <p className="text-center text-black bottom-3 left-4 font-semibold break-words">
        {translate("th", "destination-category", title.toLowerCase())}
      </p>
      <div className="ml-auto">
        {isSelected ? (
          <Icon name={Icons.squareChecked} size={14} />
        ) : (
          <FaRegSquare className="text-black" size={14} />
        )}
      </div>
    </div>
  );
};

export default CategoryCard;

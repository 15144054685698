import React, { FC } from "react";
import { useScreen } from "../../../hooks/useScreen";
import { RangeCalendar, RangeValue } from "@nextui-org/react";
import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import { CustomStyle } from "../../../models/component.model";
import classNames from "classnames";

const CALENDAR_OFFSET = 40;
const DAYS_OFFSET = 1;

type Props = {
  value: RangeValue<CalendarDate>;
  onChange: (value: RangeValue<CalendarDate>) => void;
  currentCountry: string;
  maximumDays: number;
  totalDays: number;
  doesExceedDayLimit: boolean;
};

const Calendar: FC<CustomStyle<Props>> = ({
  value,
  currentCountry,
  maximumDays,
  totalDays,
  doesExceedDayLimit,
  className,
  onChange,
}) => {
  const { screenWidth } = useScreen();
  const containerClassName = classNames("flex flex-col gap-y-3", className);
  return (
    <div className={containerClassName}>
      <h3 className="text-[18px] font-semibold">
        วันเริ่มเที่ยว-วันสุดท้ายของทริปนี้
      </h3>
      <RangeCalendar
        calendarWidth={screenWidth - CALENDAR_OFFSET}
        bottomContent={
          currentCountry ? (
            <p
              className={`w-full text-center font-bold text-sm pb-4 ${
                doesExceedDayLimit
                  ? "text-red-500 animate-error-shake"
                  : ""
              }`}
            >
              {doesExceedDayLimit
                ? `เลือกได้มากสุด ${maximumDays} วัน`
                : `จำนวนวันทั้งหมด :  ${totalDays + DAYS_OFFSET}`}
            </p>
          ) : null
        }
        aria-label="Date (Uncontrolled)"
        minValue={today(getLocalTimeZone())}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Calendar;

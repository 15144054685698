import { Stack } from "@mui/material";
import { CircularProgress } from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import { useFetchPresetTrips } from "../../../hooks/fetch-data/useFetchPresetTrips";
import FilterList from "../../../components/forms/FilterList";
import PresetTripCard from "../../../components/specific/trip-card/PresetTripCard";

const CuratedTravelExperiences = () => {
  const { data: presetTrips, isFetching: isFetchingPresetTrips } =
    useFetchPresetTrips({ isPublished: true });

  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  const cities = presetTrips
    ? Array.from(new Set(presetTrips.map((pt) => pt.itinerary.city)))
    : [];

  const matchedTrips = presetTrips
    ? presetTrips.filter(
        (pt) => pt.itinerary.city === selectedCity || selectedCity === null
      )
    : [];

  if (isFetchingPresetTrips) {
    return (
      <Stack className="h-[400px] items-center justify-center">
        <CircularProgress label="Loading preset trips..." />
      </Stack>
    );
  }

  return (
    <section className="w-full h-full mt-10 bg-[#F1F1F1] p-5">
      <h1 className="text-title w-1/2 font-semibold leading-7">
        Curated Travel Experiences
      </h1>
      <FilterList
        options={cities}
        currentValue={selectedCity}
        onSelect={setSelectedCity}
      />
      <Stack className="w-full mt-5" gap={1}>
        {matchedTrips?.map((presetTrip, index: number) => (
          <PresetTripCard key={presetTrip._id} presetTrip={presetTrip} />
        ))}
      </Stack>
    </section>
  );
};

export default CuratedTravelExperiences;

import React from "react";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import FeedbackSection from "../../../components/common/FeedbackSection";

const FeedbackSectionWrapper = () => {
  const stripeCustomer = useItineraryPageContext(
    (state) => state!.stripeCustomer
  );
  return <FeedbackSection user={stripeCustomer.user} />;
};

export default FeedbackSectionWrapper;

import { getLocalStorageItem, setLocalStorageItem } from "../../helper/util";
import { saveTripByTripId } from "../../libs/services/trip.service";

export function removedUnsavedTripIds(tripId: string) {
  const previousUnsavedTripIds =
    getLocalStorageItem<string[]>("unsavedTripIds") || [];

  const newUnsavedTripIds = previousUnsavedTripIds.filter(
    (id) => id !== tripId
  );

  setLocalStorageItem("unsavedTripIds", newUnsavedTripIds);
}

export function saveTrips(tripIds: string[]) {
  tripIds.forEach((tripId) => {
    try {
      saveTripByTripId(tripId);
    } catch (error: any) {
      console.error(`[ERROR] : ${error.message}`);
    } finally {
      removedUnsavedTripIds(tripId);
    }
  });
}

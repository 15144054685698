import React, { FC } from "react";
import { TModal } from "../../../models/component.model";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import { Stack } from "@mui/material";
import { getCenterModalProps } from "../../../libs/utils/component.util";

const PromptSignInModal: FC<TModal> = ({ onConfirm, ...modalProps }) => {
  return (
    <Modal
      classNames={{
        closeButton: "bg-white",
      }}
      {...getCenterModalProps()}
      {...modalProps}
    >
      <ModalContent>
        <ModalBody>
          <Stack
            className="text-black"
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <h1 className="text-xl font-semibold">Sign in ก่อนนร้า~</h1>
            <h2 className="text-lg font-medium text-center">
              จะได้Saveเข้ามาดูแผนของตัวเองได้ง่ายขึ้น^^
            </h2>
          </Stack>
        </ModalBody>
        <ModalFooter className="flex">
          <Button
            className="font-medium"
            fullWidth
            onClick={modalProps.onClose}
          >
            ยกเลิก
          </Button>
          <Button
            className="text-black font-medium"
            fullWidth
            color="primary"
            onClick={onConfirm}
          >
            OK!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PromptSignInModal;

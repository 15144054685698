import { useEffect } from "react";

export function useDebounce(
  fn: () => void,
  delayInMilliSeconds: number,
  dependencies: any[] = []
) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fn();
    }, delayInMilliSeconds);
    return () => {
      clearTimeout(timeoutId);
    };
  }, dependencies);
}

export enum DATE_FORMAT {
  mm_dd_yyyy = "mm-dd-yyyy",
  dd_mm_yyyy = "dd-mm-yyyy",
  yyyy_mm_dd = "yyyy-mm-dd",
}

export enum MONTHS {
  JANUARY = "Jan",
  FEBRUARY = "Feb",
  MARCH = "Mar",
  APRIL = "Apr",
  MAY = "May",
  JUNE = "Jun",
  JULY = "Jul",
  AUGUST = "Aug",
  SEPTEMBER = "Sep",
  OCTOBER = "Oct",
  NOVEMBER = "Nov",
  DECEMBER = "Dec",
}

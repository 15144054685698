import classNames from "classnames";
import React, { FC } from "react";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext.tsx";
import {
  MapViewProvider,
  useMapContext,
} from "../../../contexts/MapViewContext.tsx";
import { useFetchDestination } from "../../../hooks/fetch-data/useFetchDestination.ts";
import { CustomStyle } from "../../../models/component.model.ts";
import LoadingPage from "../../common/LoadingPage.tsx";
import Mapbox from "../../map/Mapbox.tsx";
import { formatCity } from "../../../helper/util.ts";

const MapView: FC<CustomStyle<{}>> = ({ className }) => {
  const containerClassNames = classNames(
    "lg:h-full min-h-[196px] w-full flex gap-x-3 box-border overflow-hidden",
    className
  );

  const { trip, currentSelectedDay, city } = useItineraryPageContext(
    (selector) => ({
      trip: selector!.trip,
      currentSelectedDay: selector!.currentSelectedDay,
      city: selector!.itinerary?.city,
    })
  );

  if (!trip) return null;

  return (
    <MapViewProvider
      trip={trip}
      currentSelectedDay={currentSelectedDay}
      city={city ? formatCity(city) : ""}
    >
      <main className={containerClassNames}>
        <MapBackground />
      </main>
    </MapViewProvider>
  );
};

const MapBackground: FC = () => {
  const { destinations, city } = useMapContext();

  const firstDestination = destinations[0];

  const {
    data: centerDestinationDetail,
    isFetching: isFetchingFirstDestination,
  } = useFetchDestination(city, firstDestination.place_id);

  if (isFetchingFirstDestination) {
    <LoadingPage message="Fetching destination..." />;
  }

  if (destinations.length <= 0)
    return (
      <main className="relative w-full h-[196px]">
        <div className="absolute top-[35%] translate-y-[-50%] left-1/2 w-[40px] h-[40px] translate-x-[-50%]">
          <div className="w-full h-full border-[4px] border-solid border-r-blue-500 animate-spin rounded-full "></div>
        </div>
      </main>
    );

  if (!centerDestinationDetail) return null;

  return (
    <>
      <aside className="relative w-full h-[196px] rounded-xl overflow-hidden">
        <Mapbox
          zoom={9}
          destinations={destinations}
          lat={centerDestinationDetail.coordinate?.lat}
          lng={centerDestinationDetail.coordinate?.lng}
          city={city}
        />
      </aside>
    </>
  );
};

export default MapView;

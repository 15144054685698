import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import { Stack } from "@mui/material";
import { Button, RangeValue } from "@nextui-org/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import FeedbackSection from "../../components/common/FeedbackSection";
import LoadingPage from "../../components/common/LoadingPage";
import MultiSelects from "../../components/forms/MultiSelects";
import SearchDestinations from "../../components/forms/SearchDestinations";
import { useGetSearchParams } from "../../hooks/useGetSearchParams";
import { useUser } from "../../hooks/useUser";
import { getTimeDifferenceInternationalTimes } from "../../libs/utils/date.util";
import { getCityMaxTripDays } from "../../models/countries";
import Calendar from "./_components/Calendar";
import { validateInput } from "./helper";

enum TRIP_MATE_OPTIONS {
  solo = "คนเดียว",
  lover = "คู่รัก",
  freidns = "เพื่อนซี้",
  family = "ครอบครัว",
}

const DAYS_OFFSET = 1;

type FormFields = {
  currentCountry: string;
  tripMate: string[];
};

const ItineraryInformationPage = () => {
  const navigate = useNavigate();

  const { user, loading } = useUser();

  const [country] = useGetSearchParams<string>([{ key: "country" }]);

  const [tripDate, setTripDate] = useState<RangeValue<CalendarDate>>({
    start: today(getLocalTimeZone()),
    end: today(getLocalTimeZone()).add({ weeks: 1 }),
  });

  const { watch, setValue, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      currentCountry: country || "",
      tripMate: [TRIP_MATE_OPTIONS.solo],
    },
  });

  const { tripMate, currentCountry } = watch();

  const totalDays = getTimeDifferenceInternationalTimes(
    tripDate.start,
    tripDate.end
  );

  const maximumDays = getCityMaxTripDays(currentCountry);

  const doesExceedDayLimit = totalDays > maximumDays;

  function onSubmit() {
    try {
      const { isError, message } = validateInput(
        currentCountry,
        doesExceedDayLimit,
        maximumDays,
        tripMate.length
      );

      if (isError) {
        toast.error(message);
        return;
      }

      navigate(
        `/create-itinerary?country=${currentCountry}&days=${totalDays + DAYS_OFFSET}&tripMate=${tripMate.join(
          ","
        )}&step=2&date=${tripDate.start}`
      );
    } catch (error) {
      toast.error(error.message);
    }
  }

  if (loading) return <LoadingPage message="Loading..." />;

  return (
    <Stack className="gap-y-10 lg:gap-y-16 items-left lg:items-center w-full bg-[#F7F7F9] lg:w-[40%] my-0 pt-2 mx-auto h-full text-black px-default overflow-y-scroll">
      <FeedbackSection user={user} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-y-4"
      >
        <h1 className="text-title leading-7 font-bold lg:w-max w-4/5 mb-7 mt-iphone-notch">
          มาเริ่มกันเลย! แผนเจ๋งๆรอคุณอยู่นะ
        </h1>
        <Stack className="w-full" spacing={0}>
          <SearchDestinations
            searchQuery={currentCountry}
            setSearchQuery={(value: string) =>
              setValue("currentCountry", value)
            }
          />
          <MultiSelects
            options={Object.values(TRIP_MATE_OPTIONS)}
            currentValue={tripMate}
            setter={(newValue: string[]) => setValue("tripMate", newValue)}
            label="จัดทริปสำหรับแบบ"
          />
          <Calendar
            className="mt-5"
            value={tripDate}
            onChange={(value: RangeValue<CalendarDate>) => setTripDate(value)}
            currentCountry={currentCountry}
            maximumDays={maximumDays}
            totalDays={totalDays}
            doesExceedDayLimit={doesExceedDayLimit}
          />
        </Stack>
        <Button
          className="h-[50px]"
          isDisabled={doesExceedDayLimit}
          color="primary"
          type="submit"
        >
          เลือกสไตล์เที่ยวต่อ
        </Button>
      </form>
    </Stack>
  );
};

export default ItineraryInformationPage;

import { Button } from "@nextui-org/react";
import { Provider } from "@supabase/supabase-js";
import React, { FC } from "react";
import { useNavigate } from "react-router";
import { signInWithOAuth } from "../../libs/services/auth.service";
import { capitalizeFirstLetter } from "../../libs/utils/misc.util";
import classNames from "classnames";

type Props = {
  provider: Provider | "email";
  redirectUrl?: string;
  version?: "solid" | "outline";
};

const OAuthButton: FC<Props> = ({
  provider,
  redirectUrl,
  version = "solid",
}) => {
  const navigate = useNavigate();

  const buttonClassNames = classNames("h-[50px]", {
    "shadow-button  bg-white": version === "solid",
    "border border-gray-300 bg-transparent": version === "outline",
  });

  async function handleLogin() {
    if (provider === "email") {
      navigate(`/sign-in/email?redirect=${redirectUrl}`);
      return;
    }

    await signInWithOAuth(provider, redirectUrl);
  }

  return (
    <Button onClick={handleLogin} className={buttonClassNames} fullWidth>
      {provider !== "email" ? (
        <img
          src={`/images/oauth/${provider}.png`}
          className="h-[24px] w-[24px] object-contain"
        />
      ) : null}
      <p className="font-semibold">
      เข้าสู่ระบบด้วย {capitalizeFirstLetter(provider)}
      </p>
    </Button>
  );
};

export default OAuthButton;

import classNames from "classnames";
import React, { FC } from "react";
import Icon, { Icons } from "../../../../components/common/Icon";

type Props = {
  position: "upper" | "lower" | "center";
  hasArrived: boolean;
};

const RouteLine: FC<Props> = ({ position, hasArrived }) => {
  const containerClassNames = classNames("w-[20%] h-full flex flex-col", {
    "justify-start": position === "upper",
    "justify-end": position === "lower",
  });

  const lineClassNames = classNames(
    "border-r-2  border-dashed w-1/2 h-1/2 translate-x-[1px]",
    { "border-[#EE6222]": hasArrived },
    { "border-[#8A8A8A]": !hasArrived }
  );

  const circleClassNames = classNames(
    "w-[16px] h-[16px] rounded-full border-[1px]  bg-white mx-auto",
    {
      "text-[#EE6222]": hasArrived,
      "text-[#8A8A8A]": !hasArrived,
    }
  );
  return (
    <div className={containerClassNames}>
      {(position === "upper" || position === "center") && (
        <div className={lineClassNames}></div>
      )}
      {hasArrived ? (
        <Icon
          className={circleClassNames}
          name={Icons.checkedCircle}
          size={16}
        />
      ) : (
        <Icon
          className={circleClassNames}
          name={Icons.uncheckedCircle}
          size={16}
        />
      )}
      {(position === "lower" || position === "center") && (
        <div className={lineClassNames}></div>
      )}
    </div>
  );
};

export default RouteLine;

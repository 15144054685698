import { v4 } from "uuid";

export async function generateResetPasswordToken() {
  try {
    const token = v4();
    const expires_at = new Date(Date.now() + 60 * 60 * 1000);
    return { token, expires_at };
  } catch (error: any) {
    throw new Error(error.message);
  }
}

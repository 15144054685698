import { Button } from "@nextui-org/react";
import React, { FC } from "react";
import {
  isOpenFromLine,
  openUrlWithBrowserIntent,
} from "../../libs/utils/browser.util";
import { Parent } from "../../models/component.model";

const PreventLineUserRoute: FC<Parent> = ({ children }) => {
  return isOpenFromLine() ? (
    <main className="h-full w-full flex flex-col items-center justify-center px-default gap-y-3">
      <h3 className="text-black text-center">
        ขออภัย ไม่สามารถเข้าถึงเว็บไซต์นี้ผ่าน Line Browser ได้
      </h3>
      <Button
        color="primary"
        className="font-bold"
        onClick={() =>
          openUrlWithBrowserIntent(window.location.href, "googlechrome")
        }
      >
        เปิดใน Browser อื่น
      </Button>
    </main>
  ) : (
    children
  );
};

export default PreventLineUserRoute;

import { CalendarDate } from "@internationalized/date";
import { extractTripDate, generateArray } from "../../helper/util";
import { ROLES } from "./type";
import { Empty } from "../../models/misc.model";

export function getTotalDaysForDayCards(
  tripDuration: number,
  tripDate: string
): { title: string; date: CalendarDate }[] {
  return generateArray(tripDuration).map((_, dayIndex) => {
    const { day, month, year } = extractTripDate(tripDate);

    const calendarDate = new CalendarDate(year, month, day).add({
      days: dayIndex,
    });

    return {
      title: `Day ${String(dayIndex + 1).padStart(2, "0")}`,
      date: calendarDate,
    };
  });
}

export function getUserRole({
  userId,
  allowedEmails,
  userEmail,
  tripOwnerUserId,
  isAdmin,
}: {
  userId: string | Empty;
  allowedEmails: string[] | Empty;
  tripOwnerUserId: string | Empty;
  userEmail: string | Empty;
  isAdmin: boolean;
}): ROLES {
  if (isAdmin) return ROLES.ADMIN;

  if (!userId || !userEmail) return ROLES.UNAUTHORIZED;

  if (userId === tripOwnerUserId) return ROLES.TRIP_OWNER;

  if (!allowedEmails) return ROLES.UNAUTHORIZED;

  if (allowedEmails.includes(userEmail)) return ROLES.TRIP_MATE;

  return ROLES.UNAUTHORIZED;
}

import { Stack } from "@mui/material";
import classNames from "classnames";
import React, { FC, useState } from "react";
import { useContextSelector } from "use-context-selector";
import { ItineraryPageContext } from "../../contexts/ItineraryPageContext";
import { CustomStyle } from "../../models/component.model";
import InviteFriendButton from "../../routes/itinerary/_components/InviteFriendButton";
import PDFButton from "../../routes/itinerary/_components/PDFButton";
import { ROLES } from "../../routes/itinerary/type";
import { Logo } from "../Logo";
import Hamburger from "./Hamburger";
import SideBar from "./sidebar/SideBar";

type Props = {
  onInviteFriends: () => void;
};

const ItineraryNavbar: FC<CustomStyle<Props>> = ({
  className,
  onInviteFriends,
}) => {
  const containerClassNames = classNames(
    "flex items-center justify-between h-[40px] w-full text-black",
    className
  );
  const [showUserProfile, setShowUserProfile] = useState(false);

  const userRole = useContextSelector(ItineraryPageContext, (selector) => {
    return selector!.userRole;
  });

  const tripDetail = useContextSelector(ItineraryPageContext, (selector) => {
    return selector!.itinerary;
  });

  return (
    <nav id="cy-nav-bar" className={containerClassNames}>
      <SideBar
        isShow={showUserProfile}
        onClose={() => setShowUserProfile(false)}
        onOpen={() => setShowUserProfile(true)}
      />
      <Hamburger className="z-[10]" onClick={() => setShowUserProfile(true)} />
      <Logo size="small" />
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        {userRole === ROLES.TRIP_OWNER && (
          <>
            {tripDetail && <PDFButton tripDetail={tripDetail} />}
            <InviteFriendButton
              onClick={onInviteFriends}
              className="translate-y-[-4px]"
            />
          </>
        )}
      </Stack>
    </nav>
  );
};

export default ItineraryNavbar;

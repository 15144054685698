import { User } from "@supabase/supabase-js";
import React, { createContext, FC, useContext } from "react";
import { useUser } from "../hooks/useUser";
import { Parent } from "../models/component.model";
import { useNavigate } from "react-router";
import LoadingPage from "../components/common/LoadingPage";

type UserProtectedRouteContextType = {
  user: User | null;
  loadingUser: boolean;
};

const UserProtectedRouteContext = createContext<
  UserProtectedRouteContextType | undefined
>(undefined);

const UserProtectedRouteProvider: FC<Parent> = ({ children }) => {
  const { user, loading } = useUser();

  const navigate = useNavigate();

  if (loading) {
    return <LoadingPage />;
  }

  if (!user) {
    navigate("/sign-in");
    return null;
  }

  return (
    <UserProtectedRouteContext.Provider value={{ user, loadingUser: loading }}>
      {children}
    </UserProtectedRouteContext.Provider>
  );
};

export const useUserProtecteRoute = () => {
  const context = useContext(UserProtectedRouteContext);
  if (context === undefined) {
    throw new Error(
      "useUserProtecteRoute must be used within a UserProtectedRouteProvider"
    );
  }
  return context;
};

export default UserProtectedRouteProvider;

import { User } from "@supabase/supabase-js";
import { useQueryState } from "nuqs";
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useFetchTrip } from "../hooks/useFetchTrip";
import { useGetSearchParams } from "../hooks/useGetSearchParams";
import { useStripeCustomerInfo } from "../hooks/useStripeCustomerInfo";
import { Destination } from "../models/destination";
import { Empty, QueryStateSetAction } from "../models/misc.model";
import { StripeCustomer } from "../models/stripeCustomer.model";
import { Trip, TripDetail } from "../models/trip.model";
import { getUserRole } from "../routes/itinerary/helper";
import { ROLES } from "../routes/itinerary/type";
import { createContext, useContextSelector } from "use-context-selector";

const DEFAULT_SELECTED_DAY = "Day1";

type ItineraryPageContextType = {
  stripeCustomer: {
    stripeCustomerInfo: StripeCustomer | Empty;
    loadingStripeInfo: boolean;
    loadingUserInfo: boolean;
    user: User | null;
  };
  tripId: string | null;
  itinerary: TripDetail | null;
  trip: Trip | Empty;
  view: string | null;
  currentSelectedDay: string;
  selectedSuggestedDestination: Destination | null;
  userRole: ROLES;
  setSelectedSuggestedDestination: Dispatch<SetStateAction<Destination | null>>;
  onRefreshTrip: (...props: any) => void;
  setCurrentSelectedDay: QueryStateSetAction<string>;
  handleUpdateItinerary: any;
  loadingTrip: boolean;
} | null;

type ProviderProps = {
  children: ReactNode;
};

export const ItineraryPageContext =
  createContext<ItineraryPageContextType>(null);

export const ItineraryPageProvider: FC<ProviderProps> = ({ children }) => {
  const stripeCustomer = useStripeCustomerInfo();

  const [tripId, view] = useGetSearchParams<string | null>([
    { key: "tripId" },
    { key: "view" },
  ]);

  const {
    data: trip,
    isFetching: loadingTrip,
    refetch: onRefreshTrip,
  } = useFetchTrip(tripId as string);

  const [itinerary, setItinerary] = useState<TripDetail | null>(null);

  const [currentSelectedDay, setCurrentSelectedDay] = useQueryState("day", {
    defaultValue: DEFAULT_SELECTED_DAY,
  });

  const [selectedSuggestedDestination, setSelectedSuggestedDestination] =
    useState<Destination | null>(null);

  const userRole = getUserRole({
    userId: stripeCustomer?.user?.id,
    allowedEmails: trip?.tripMateEmails,
    tripOwnerUserId: trip?.userId,
    userEmail: stripeCustomer?.user?.email,
    // TODO : Use real isAdmin value
    isAdmin: false,
  });

  useEffect(() => {
    if (!trip) return;
    setItinerary(trip.itinerary);
  }, [trip?._id]);

  function handleUpdateItinerary(newItinerary: any) {
    setItinerary(
      (prev) =>
        ({
          ...prev,
          itinerary: newItinerary,
        }) as TripDetail
    );
  }

  return (
    <ItineraryPageContext.Provider
      value={{
        stripeCustomer,
        tripId,
        itinerary,
        trip,
        view,
        currentSelectedDay,
        loadingTrip,
        selectedSuggestedDestination,
        userRole,
        setSelectedSuggestedDestination,
        setCurrentSelectedDay,
        handleUpdateItinerary,
        onRefreshTrip,
      }}
    >
      {children}
    </ItineraryPageContext.Provider>
  );
};

export const useItineraryPageContext = <D,>(
  selector: (value: ItineraryPageContextType) => D
) => useContextSelector(ItineraryPageContext, selector);

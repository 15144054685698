import { isInTime } from "../../helper/util";
import { isInThePast, isToday } from "./date.util";

export function hasArrived({
  day,
  month,
  year,
  time,
}: {
  day: number;
  month: number;
  year: number;
  time: string;
}) {
  if (isInThePast({ day, month, year })) {
    return true;
  }

  if (!isToday({ day, month, year })) {
    return false;
  }

  if (!isInTime(time)) {
    return false;
  }

  return true;
}
import { Stack } from "@mui/material";
import React, { ReactNode } from "react";
import { FaRegClock } from "react-icons/fa";
import { Link } from "react-router-dom";
import CONSTANTS from "../../../constants";
import Icon, { Icons } from "../Icon";

const MENUS: { title: string; link: string; icon: ReactNode }[] = [
  { title: "หน้าหลัก", link: "/", icon: <Icon name={Icons.home} size={22} /> },
  {
    title: "แผนเที่ยวที่ผ่านมาแล้ว",
    link: "/past-trips",
    icon: <FaRegClock size={22} />,
  },
  {
    title: "บทความ",
    link: CONSTANTS.ELEPLAN_BLOG_URL,
    icon: <Icon name={Icons.articles} size={22} />,
  },
];

const Menus = () => {
  return (
    <Stack className="mt-[54px]" spacing={3.5}>
      {MENUS.map((menu, _) => {
        return (
          <Link to={menu.link} key={menu.title}>
            <Stack direction="row" spacing={1} alignItems="center">
              <span>{menu.icon}</span>
              <span className="font-semibold">{menu.title}</span>
            </Stack>
          </Link>
        );
      })}
    </Stack>
  );
};

export default Menus;

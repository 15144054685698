import classNames from "classnames";
import React, { FC, forwardRef } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CustomStyle, TInput } from "../../models/component.model";

const MyInput = forwardRef<HTMLInputElement, CustomStyle<TInput>>(
  (
    {
      className,
      label,
      type,
      onTogglePasswordVisibility,
      showPassword,
      errorMessage,
      ...inputProps
    },
    ref
  ) => {
    const inputContainerClassname = classNames(
      "bg-white shadow-button h-[50px] rounded-lg flex items-center justify-between px-3"
    );
    const inputClassname = classNames(
      "border-none outline-none text-sm placeholder-black w-full",
      className
    );

    return (
      <div className="flex flex-col w-full gap-y-2">
        {label && <label className="text-gray-400 text-sm">{label}</label>}
        <div className={inputContainerClassname}>
          <input
            ref={ref}
            type={type === "password" && showPassword ? "text" : type}
            {...inputProps}
            className={inputClassname}
          />
          {type === "password" && (
            <button
              className="focus:outline-none bg-white outline-none border-none"
              type="button"
              onClick={onTogglePasswordVisibility}
              aria-label="toggle password visibility"
            >
              {showPassword ? (
                <FaEye className="text-lg bg-white text-default-400 pointer-events-none" />
              ) : (
                <FaEyeSlash className="text-lg bg-white text-default-400 pointer-events-none" />
              )}
            </button>
          )}
        </div>
        {errorMessage && (
          <span className="text-red-500 text-sm text-end">{errorMessage}</span>
        )}
      </div>
    );
  }
);
export default MyInput;

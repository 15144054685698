import classNames from "classnames";
import React, { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const SafeArea: FC<Props> = ({ children}) => {
  const containerClass = classNames("w-full px-default");
  return <div className={containerClass}>{children}</div>;
};

export default SafeArea;

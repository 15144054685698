import classNames from "classnames";
import React, { forwardRef, useRef } from "react";
import { CustomStyle, TInput } from "../../models/component.model";
import { FaClock } from "react-icons/fa";

const TimeInput = forwardRef<
  HTMLInputElement,
  CustomStyle<Omit<TInput, "type">>
>(({ className, label, showPassword, errorMessage, ...inputProps }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const inputContainerClassname = classNames(
    "h-[40px] rounded-lg flex items-center gap-x-2 w-full bg-gray-100 px-3"
  );
  const inputClassname = classNames(
    "border-none outline-none text-sm placeholder-black bg-transparent",
    className
  );

  function handleContainerClick() {
    inputRef.current?.focus();
  }

  return (
    <div
      onClick={handleContainerClick}
      className="flex flex-col w-full gap-y-2"
    >
      {label && <label className="text-black text-sm font-semibold">{label}</label>}
      <div className={inputContainerClassname}>
        <FaClock className="text-gray-300"/>
        <input
          ref={(node) => {
            if (ref) {
              if (typeof ref === "function") {
                ref(node);
              } else {
                ref.current = node;
              }
            }
            inputRef.current = node;
          }}
          type={"time"}
          {...inputProps}
          className={inputClassname}
        />
      </div>
      {errorMessage && (
        <span className="text-red-500 text-sm text-end">{errorMessage}</span>
      )}
    </div>
  );
});
export default TimeInput;

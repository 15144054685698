import React, { FC } from "react";
import { useItineraryPageContext } from "../../../../contexts/ItineraryPageContext";
import { ItineraryDetailClass } from "../../../../models/classes/ItineraryDetailClass";
import Destination from "./Destination";
import { getDayIndex } from "../../../../helper/destination.helper";
import { DestinationWithTime } from "../type";
import { formatCity } from "../../../../helper/util";

type Props = {
  onSelectEditDestination: (destination: DestinationWithTime) => void;
};

function getRouteLinePosition(
  index: number,
  lastIndex: number
): "upper" | "lower" | "center" {
  switch (index) {
    case 0:
      return "lower";
    case lastIndex:
      return "upper";
    default:
      return "center";
  }
}

const DestinationList: FC<Props> = ({ onSelectEditDestination }) => {
  const { currentSelectedDay, itinerary } = useItineraryPageContext(
    (selector) => ({
      currentSelectedDay: selector!.currentSelectedDay,
      itinerary: selector!.itinerary,
    })
  );

  if (!itinerary || !currentSelectedDay) return null;

  const { date } = itinerary;

  if (!date) return <h1>This trip does not have a date</h1>;

  const itineraryDetail = new ItineraryDetailClass(
    itinerary.itinerary[currentSelectedDay]
  );

  const todayDestinations = itineraryDetail.getDestinations();

  return (
    <div className="flex flex-col mx-default mt-2">
      {todayDestinations.map((destination, destinationIndex) => {
        const destinationTime =
          itineraryDetail.getDestinationTimes()[destinationIndex];

        return (
          <Destination
            onClick={() =>
              onSelectEditDestination({ ...destination, time: destinationTime })
            }
            tripDate={date}
            selectedDayIndex={getDayIndex(currentSelectedDay)}
            key={`${destination.place_id}-${destinationIndex}`}
            time={destinationTime}
            placeId={destination.place_id}
            city={formatCity(itinerary.city)}
            routeLinePosition={getRouteLinePosition(
              destinationIndex,
              todayDestinations.length - 1
            )}
          />
        );
      })}
    </div>
  );
};

export default DestinationList;

import { Stack } from "@mui/material";
import React, { FC } from "react";
import { getDestinationCategoryTagStyle } from "../../libs/utils/component.util";
import { translate } from "../../libs/utils/lang.util";

type Props = {
  categories: string[];
  returnedItems?: number;
};

const DestinationCategories: FC<Props> = ({
  categories,
  returnedItems = categories.length,
}) => {
  return (
    <Stack direction={"row"} gap={1}>
      {categories
        ?.map((category) => (
          <div key={category} className={getDestinationCategoryTagStyle()}>
            {translate("th", "destination-category", category.toLowerCase())}
          </div>
        ))
        .slice(0, returnedItems)}
    </Stack>
  );
};

export default DestinationCategories;

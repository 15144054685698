import { CircularProgress } from "@nextui-org/react";
import React from "react";
import "../../App.css";
import FeedbackSection from "../../components/common/FeedbackSection";
import SafeArea from "../../components/common/SafeArea";
import NavBar from "../../components/pages/home/Navbar";
import { useFetchFeatureFlags } from "../../hooks/fetch-data/useFetchFeatureFlags";
import { useUser } from "../../hooks/useUser";
import CuratedTravelExperiences from "./_components/CuratedTravelExperiences";
import ItineraryList from "./_components/ItineraryList";

function HomePage() {
  const { user } = useUser();

  const {
    data: featureFlags,
    isFetching: isFetchingFeatureFlags,
    isError,
    error,
  } = useFetchFeatureFlags();

  const isPresetItineraryEnabled = featureFlags?.["preset_itinerary"] ?? false;

  return (
    <div className="flex flex-col justify-start items-center min-h-screen overflow-x-hidden w-full lg:px-[10%] overflow-scroll bg-[#FCFCFC] pt-2">
      <SafeArea>
        <NavBar className="mt-navbar" user={user} />
      </SafeArea>
      <SafeArea>
        <ItineraryList />
        {/* <BlogSection /> */}
      </SafeArea>
      {isFetchingFeatureFlags ? (
        <CircularProgress className="h-[300px]" />
      ) : isError ? (
        <p>{error.message}</p>
      ) : (
        isPresetItineraryEnabled && <CuratedTravelExperiences />
      )}
      <FeedbackSection user={user} />
    </div>
  );
}

export default HomePage;

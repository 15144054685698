import { ModalPlacement } from "../../models/component.model";

export function getCenterModalProps(): {
  placement: ModalPlacement;
  className: string;
} {
  return {
    placement: "center",
    className: "m-5 p-5",
  };
}

export function getDestinationCategoryTagStyle(): string {
  return "focus:outline-none focus:border-gray-300 px-4 py-1.5 bg-[#ED612314] text-sm rounded-lg min-w-max";
}

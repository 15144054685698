import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Textarea,
} from "@nextui-org/react";
import React, { FC } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { submitFeedBack } from "../../libs/services/feedbacks.service";
import { TModal } from "../../models/component.model";
import { Feedback, FeedbackSchema } from "../../models/feedback.model";

const SCORE_LABELS = ["😭", "😟", "😐", "😊", "🤩"];
type FormFields = Pick<Feedback, "comment" | "score">;

const FeedbackModal: FC<TModal & { userId?: string }> = (props) => {
  const methods = useForm<FormFields>({
    defaultValues: {
      score: 0,
      comment: "",
    },
    resolver: zodResolver(
      FeedbackSchema.omit({ id: true, created_at: true, user_id: true })
    ),
  });
  const {
    setValue,
    formState: { errors },
  } = methods;

  function handleUpdateScore(newScore: number) {
    setValue("score", newScore);
  }

  return (
    <Modal
      classNames={{ closeButton: "bg-white" }}
      className="text-black"
      placement="center"
      {...props}
    >
      <ModalContent className="mx-5">
        <ModalHeader>
          <Stack spacing={1}>
            <h2 className="w-3/4">เราให้ความสำคัญกับความคิดเห็นของคุณ</h2>
            <p className="text-gray-400 font-medium text-sm">
              ช่วยบอกเราหน่อยว่าคุณรู้สึกอย่างไรเมื่อใช้งานแอปของเรา
              ข้อเสนอแนะของคุณมีความสำคัญต่อการพัฒนาและปรับปรุงให้ดียิ่งขึ้น
            </p>
          </Stack>
        </ModalHeader>
        <ModalBody className="mb-4">
          <FormProvider {...methods}>
            <ScoreInput
              score={methods.watch("score")}
              setScore={handleUpdateScore}
            />
            {errors.score && (
              <p className="text-red-500 text-sm">{errors.score.message}</p>
            )}
            <Stack
              className="text-gray-400 text-sm"
              direction={"row"}
              justifyContent={"space-between"}
            >
              <p>ไม่พึงพอใจ</p>
              <p>พึงพอใจอย่างมาก</p>
            </Stack>
            <FeedbackForm onClose={props.onClose} userId={props.userId} />
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ScoreInput: FC<{
  score: number;
  setScore: (score: number) => void;
}> = ({ score, setScore }) => {
  return (
    <div className="flex justify-between items-center gap-5">
      {SCORE_LABELS.map((label, index) => (
        <button
          key={index}
          onClick={() => setScore(index)}
          className={`p-2 rounded-xl bg-gray-100 w-full transition-all ${
            score === index ? "bg-primary text-white" : ""
          }`}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

const FeedbackForm: FC<Pick<TModal, "onClose"> & { userId?: string }> = ({
  onClose,
  userId,
}) => {
  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useFormContext<FormFields>();

  async function onSubmit(data: FormFields) {
    try {
      toast.loading("Submitting your feedback...");
      await submitFeedBack({ ...data, user_id: userId });
      toast.dismiss();
      toast.success("Thank you for your feedback!");
      onClose();
      resetForm();
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message);
    }
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-y-4"
      action=""
    >
      <h3 className="font-semibold">
        ความคิดเห็นของคุณ{" "}
        <span className="text-sm text-gray-400">(ไม่บังคับ)</span>
      </h3>
      <Textarea
        isInvalid={Boolean(errors.comment)}
        placeholder="กรอกความคิดเห็นของคุณ"
        {...register("comment")}
      />
      <Button
        type="submit"
        className="text-black font-semibold"
        color="primary"
        fullWidth
      >
        ส่งความคิดเห็น
      </Button>
    </form>
  );
};
export default FeedbackModal;

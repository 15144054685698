import { useQuery } from "@tanstack/react-query";
import { itineraryGeneratorClient } from "../../configs/axios.config";
import { formatCity } from "../../helper/util";

export function useFetchSuggestedDestinations(
  city: string,
  categories: string[]
) {
  const query = useQuery({
    queryKey: ["fetch suggested destinations", city, categories],
    queryFn: async () => {
      if (!city || !categories) {
        throw new Error("City and categories are required");
      }

      const formattedCategories = categories.reduce(
        (accu, val) => accu + "," + val
      );

      const {
        data: { destinations },
      } = await itineraryGeneratorClient.get(
        `${process.env.URPLANTRIP_GENERATOR_ENDPOINT}/${process.env.API_VERSION}/destinations/suggests/${formatCity(city)}/${formattedCategories}`
      );
      return destinations;
    },
    staleTime: 0,
    refetchOnWindowFocus: false,
  });
  return query;
}

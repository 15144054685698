import { MapIcon } from "@heroicons/react/24/outline";
import { Stack } from "@mui/material";
import React, { FC, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { BottomSheet, BottomSheetProps } from "react-spring-bottom-sheet";
import DestinationCategories from "../../../../components/common/DestinationCategories";
import LoadingPage from "../../../../components/common/LoadingPage";
import Rating from "../../../../components/common/Rating";
import ActivatedSection from "../../../../components/specific/bottom-sheet/activated-section/ActivatedSection";
import BottomSheetContainer from "../../../../components/specific/bottom-sheet/BottomSheetContainer";
import BottomSheetNavbar from "../../../../components/specific/bottom-sheet/BottomSheetNavbar";
import DestinationDescription from "../../../../components/specific/destination/DestinationDescription";
import DestinationWeekDay from "../../../../components/specific/destination/DestinationWeekDay";
import { useItineraryPageContext } from "../../../../contexts/ItineraryPageContext";
import { getDestinationMapUrl } from "../../../../helper/destination.helper";
import {
  deleteDestination,
  updateItineraryTime,
} from "../../../../helper/itinerary.helper";
import { convertStringToArray, formatCity } from "../../../../helper/util";
import { useFetchDestination } from "../../../../hooks/fetch-data/useFetchDestination";
import { updateTripById } from "../../../../libs/services/trip.service";
import { DestinationWithTime } from "../type";
import EditDestinationForm from "./EditDestinationForm";
import { getActivatedSections, getDefaultActivatedSection } from "./helper";
import { SECTION } from "./type";
import { useNavigate } from "react-router";

type Props = {
  selectedDestination: DestinationWithTime;
};

const EditDestinationSheet: FC<Props & Omit<BottomSheetProps, "children">> = ({
  selectedDestination,
  ...bottomSheeetProps
}) => {
  const {
    itinerary,
    currentSelectedDay,
    tripId,
    handleUpdateItinerary,
    userRole,
  } = useItineraryPageContext((selector) => ({
    itinerary: selector!.itinerary,
    currentSelectedDay: selector!.currentSelectedDay,
    tripId: selector!.tripId,
    handleUpdateItinerary: selector!.handleUpdateItinerary,
    userRole: selector!.userRole,
  }));

  const { place_id, time } = selectedDestination;

  const navigate = useNavigate();

  const { data: destination, isLoading: isLoadingDestination } =
    useFetchDestination(formatCity(itinerary?.city ?? ""), place_id);

  const { onDismiss } = bottomSheeetProps;

  const [selectedSection, setSelectedSection] = useState<SECTION>(
    getDefaultActivatedSection(userRole)
  );

  const ALL_SECTIONS: { title: string; value: SECTION }[] = useMemo(() => {
    return getActivatedSections(userRole);
  }, [userRole]);

  async function handleDeleteDestination() {
    try {
      if (!itinerary || !currentSelectedDay || !tripId) return;

      const doesCurrentHaveOneDestination =
        Object.keys(itinerary.itinerary[currentSelectedDay]).length <= 1;

      if (doesCurrentHaveOneDestination) {
        toast.error("ไม่สามารถลบสถานทีสุดท้ายได้");
        return;
      }

      toast.loading("กำลังลบสถานที่เที่ยว...");

      const updatedItinerary = deleteDestination(
        itinerary.itinerary,
        currentSelectedDay,
        selectedDestination
      );

      await updateTripById(tripId, updatedItinerary);

      toast.dismiss();

      navigate(0);

      if (onDismiss) {
        onDismiss();
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleUpdateDestinationTime(
    originTime: string,
    targetTime: string
  ) {
    try {
      if (!itinerary || !currentSelectedDay) return;

      const updatedItinerary = updateItineraryTime(
        itinerary.itinerary,
        currentSelectedDay,
        originTime,
        targetTime
      );

      await updateTripById(tripId as string, updatedItinerary);

      handleUpdateItinerary(updatedItinerary);
    } catch (error) {
      toast.error(error.message);
    }
  }

  if (!destination) {
    return null;
  }

  const { name_place, category, rating, DCT, operating_hours } = destination;

  return (
    <BottomSheet {...bottomSheeetProps}>
      <BottomSheetContainer>
        {isLoadingDestination ? (
          <LoadingPage />
        ) : (
          <Stack spacing={2}>
            <BottomSheetNavbar>
              <h2 className="text-[18px] font-bold line-clamp-1">
                {name_place}
              </h2>
              <MapIcon
                onClick={() => {
                  window.location.assign(getDestinationMapUrl(name_place));
                }}
                className="size-6"
              />
            </BottomSheetNavbar>

            <div className="flex justify-between">
              <DestinationCategories
                categories={convertStringToArray(category, ",")}
              />
              <Rating rating={rating || 0} />
            </div>

            <ActivatedSection<SECTION>
              showingSection={selectedSection}
              sections={ALL_SECTIONS}
              sectionSetter={setSelectedSection}
            />

            {selectedSection === "edit" && tripId && (
              <EditDestinationForm
                tripId={tripId}
                placeId={place_id}
                onDeleteDestination={handleDeleteDestination}
                onSaveDestination={handleUpdateDestinationTime}
                onCloseModal={onDismiss}
                time={time}
                note={selectedDestination.note ?? ""}
              />
            )}

            {selectedSection === "overview" && (
              <DestinationDescription description={DCT} />
            )}

            {selectedSection === "openHour" && (
              <DestinationWeekDay
                weekDays={operating_hours?.weekday_text || []}
              />
            )}
          </Stack>
        )}
      </BottomSheetContainer>
    </BottomSheet>
  );
};

export default EditDestinationSheet;

import React, { FC, useEffect, useRef } from "react";
import mapboxgl, { Map } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { colors } from "../../configs/colors.config";
import { DestinationWithLocation } from "../../models/trip.model";
import { getDestination } from "../../libs/services/destination.service";

mapboxgl.accessToken = process.env.MAP_BOX_PUBLIC_ACCESS_TOKEN!;

const markerStyles = {
  backgroundColor: colors.lightSalmon,
  padding: "5px",
  width: "60px",
  height: "60px",
  borderRadius: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
};

const markerContentStyles = {
  fontSize: "1rem",
  color: "white",
  fontWeight: "bold",
};

const applyStyles = (element, styles) => {
  Object.entries(styles).forEach(([key, value]) => {
    element.style[key] = value;
  });
};

const createMarker = (index) => {
  const marker = document.createElement("div");

  const markerContent = document.createElement("div");

  applyStyles(marker, markerStyles);

  markerContent.textContent = index + 1;

  applyStyles(markerContent, markerContentStyles);

  marker.append(markerContent);
  
  return marker;
};

type MapBoxProps = {
  lat: number;
  lng: number;
  zoom: number;
  destinations: DestinationWithLocation[];
  city: string;
};

const Mapbox: FC<MapBoxProps> = ({ lat, lng, zoom, destinations, city }) => {
  const mapContainerRef = useRef<any>(null);
  const map = useRef<Map | any>(null);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.current.on("load", async () => {

      map.current.resize();

      destinations.forEach(async (destination, index) => {
        const marker = createMarker(index);

        const destinationDetail = await getDestination(
          city,
          destination.place_id
        );

        if (!destinationDetail) return;

        if (!destinationDetail.coordinate) return;

        const { lng, lat } = destinationDetail.coordinate;

        if (!lng || !lat) return;

        try {
          new mapboxgl.Marker(marker)
            .setLngLat({ lng, lat })
            .addTo(map.current);
        } catch (error: any) {
          console.error(`Error adding marker to map: ${error.message}`);
        }
      });
    });

    return () => {
      map.current.remove();
    };
  }, [lat, lng, zoom, destinations]);

  return <div className="w-full h-full rounded-2xl" ref={mapContainerRef} />;
};

export default Mapbox;

import {
  LANGUAGES,
  TRANSLATE_CONTENTS,
  TRANSLATE_SECTIONS,
} from "../../configs/constants/languages.constant";
import { Lang, TranslateSection } from "../../models/languages.model";

export function doesLangExist(lang: string): lang is Lang {
  return LANGUAGES.includes(lang as Lang);
}

export function doesTranslateSectionExist(
  section: string
): section is TranslateSection {
  return TRANSLATE_SECTIONS.includes(section as TranslateSection);
}

export function doesTranslateKeyExist(
  section: TranslateSection,
  key: string
): boolean {
  return Object.keys(TRANSLATE_CONTENTS[section]).includes(key);
}

export function translateDestinationCategory(
  lang: Lang,
  section: TranslateSection,
  key: string
): string {
  if (!doesTranslateKeyExist(section, key)) return "";

  return TRANSLATE_CONTENTS[section][key][lang];
}

export function translate(
  desiredLang: Lang,
  section: TranslateSection,
  key: string
): string {
  if (!doesLangExist(desiredLang)) throw new Error("Invalid language");

  if (!doesTranslateSectionExist(section))
    throw new Error("Invalid translate section");

  switch (section) {
    case "destination-category":
      return translateDestinationCategory(desiredLang, section, key);
    default:
      throw new Error("Invalid translate section");
  }
}

import { CalendarDate } from "@internationalized/date";
import classNames from "classnames";
import React, { FC } from "react";
import { convertMonthToText } from "../../../helper/util";
import { TButton } from "../../../models/component.model";
import { translateToThaiMonth } from "../../../libs/utils/date.util";

type Props = {
  dayTitle: string;
  date: CalendarDate;
  isSelected: boolean;
};

function translateDayTitle(dayTitle: string) {
  const [_, date] = dayTitle.split(" ");
  return `วันที่ ${date}`;
}

const DayCard: FC<Props & TButton> = ({
  dayTitle,
  date,
  isSelected,
  onClick,
}) => {
  const containerClassNames = classNames(
    "flex flex-col p-3 min-w-[100px] h-[74px] rounded-xl w-full items-center justify-center transition-all",
    { "bg-[#F6EBE8]": isSelected },
    { "shadow-button bg-white": !isSelected }
  );

  const dayTitleClassNames = classNames("font-bold text-base", {
    "text-[#EE6222]": isSelected,
  });

  const dayClassNames = classNames(
    "text-sm text-[#8A8A8A] font-semibold text-[12px]",
    {
      "text-black": isSelected,
    }
  );

  const { day, month } = date;

  return (
    <div onClick={onClick} className={containerClassNames}>
      <p className={dayTitleClassNames}>{translateDayTitle(dayTitle)}</p>
      <p className={dayClassNames}>
        {day} {translateToThaiMonth(convertMonthToText(month))}
      </p>
    </div>
  );
};

export default DayCard;

import { Stack } from "@mui/material";
import { Button, Input, select } from "@nextui-org/react";
import React, { FC, useState } from "react";
import toast from "react-hot-toast";
import { FaLink } from "react-icons/fa";
import { BottomSheet, BottomSheetProps } from "react-spring-bottom-sheet";
import { useItineraryPageContext } from "../../../../contexts/ItineraryPageContext";
import {
  addTripMates,
  removeTripMates,
} from "../../../../libs/services/trip.service";
import TripMateList from "./TripMateList";
import { isEmailFormatValid } from "../../../../helper/util";

const InviteTripMateSheet: FC<Omit<BottomSheetProps, "children">> = ({
  ...bottomSheetProps
}) => {
  const [emailInput, setEmailInput] = useState("");

  const [isPending, setIsPending] = useState(false);

  const { trip, onRefreshTrip } = useItineraryPageContext((selector) => ({
    trip: selector!.trip,
    onRefreshTrip: selector!.onRefreshTrip,
  }));

  if (!trip) return null;

  const tripMateEmails = trip?.tripMateEmails ?? [];
  const tripId = trip._id;

  async function handleAddTripMateEmail() {
    try {
      if (!emailInput) return;

      if (isEmailFormatValid(emailInput) === false) {
        toast.error("Invalid email format");
        return;
      }

      if (tripMateEmails.includes(emailInput)) {
        toast.error("Trip mate has already been added");
        setEmailInput("");
        return;
      }

      setIsPending(true);

      await addTripMates(tripId, [emailInput]);

      setEmailInput("");

      onRefreshTrip();

      toast.success("Trip mate has been added");
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsPending(false);
    }
  }

  async function handleRemoveTripMateEmail(email: string) {
    try {
      setIsPending(true);

      await removeTripMates(tripId, [email]);

      onRefreshTrip();

      toast.success("Trip mate has been removed");
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsPending(false);
    }
  }

  function handleCopyTripLink() {
    navigator.clipboard.writeText(
      `${window.location.origin}/itinerary?tripId=${tripId}`
    );
    toast.success("คัดลอกลิงค์สำเร็จ");
  }

  return (
    <BottomSheet {...bottomSheetProps}>
      <Stack className="text-black px-bottom-sheet pb-bottom-sheet" spacing={2}>
        <h1 className="text-xl font-bold">เชิญเพื่อนรวมเดินทาง</h1>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Input
            placeholder="อีเมล์ของเพื่อนร่วมทาง"
            className="mt-4"
            onChange={(e) => setEmailInput(e.target.value)}
            value={emailInput}
          />
          <Button
            isDisabled={isPending || !emailInput}
            isLoading={isPending}
            onClick={handleAddTripMateEmail}
            className="text-white font-bold"
            color="primary"
          >
            เพิ่ม
          </Button>
        </Stack>
        {tripMateEmails.length > 0 ? (
          <TripMateList
            onRemoveEmail={handleRemoveTripMateEmail}
            emails={tripMateEmails}
          />
        ) : (
          <p className="text-gray-400 text-center font-bold text-sm">
            คุณยังไม่มีเพื่อนร่วมทาง
          </p>
        )}
        <Button
          onClick={handleCopyTripLink}
          className="font-bold text-black h-button"
          variant="bordered"
          color="primary"
          startContent={<FaLink />}
        >
          คัดลอกลิงค์แผนเที่ยว
        </Button>
      </Stack>
    </BottomSheet>
  );
};

export default InviteTripMateSheet;

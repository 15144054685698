import classNames from "classnames";
import React, { FC } from "react";
import { TButton } from "../../../../models/component.model";

type Props = {
  isSelected: boolean;
  title: string;
};

const Section: FC<Props & TButton> = ({ isSelected, title, onClick }) => {
  const containerClassNames = classNames("px-2 py-2 w-full rounded-xl transition-background text-center", {
    "bg-primary text-white font-black": isSelected,
    "text-[#A09EAB] font-bold": !isSelected,
  });
  return (
    <div onClick={onClick} className={containerClassNames}>
      {title}
    </div>
  );
};

export default Section;

import React, { useState } from "react";
import EditDestinationSheet from "./_components/edit-destination-sheet/EditDestinationSheet";
import { DestinationWithTime } from "./_components/type";
import ItineraryNavbar from "../../components/common/ItineraryNavbar";
import FeedbackSectionWrapper from "./_components/FeedbackSectionWrapper";
import InviteTripMateSheet from "./_components/invite-trip-mate-sheet/InviteTripMateSheet";
import SelectDays from "./_components/SelectDays";
import MapView from "../../components/pages/itinerary/MapView";
import DisplayForTripOwnerSection from "./_components/DisplayForTripOwnerSection";
import DestinationList from "./_components/destination-list/DestinationList";

function ItineraryPage() {
  const [selectedEditDestination, setSelectedEditDestination] =
    useState<DestinationWithTime | null>(null);

  const [showInviteFreindSheet, setShowInviteFreindSheet] = useState(false);

  function handleSelectEditDestination(destination: DestinationWithTime) {
    setSelectedEditDestination(destination);
  }

  return (
    <div className="relative flex flex-col w-full h-full overflow-hidden bg-plain">
      <div className="w-full lg:hidden overflow-y-scroll h-full gap-y-3 flex flex-col overflow-hidden bg-plain">
        <ItineraryNavbar
          className="px-default mt-navbar"
          onInviteFriends={() => setShowInviteFreindSheet(true)}
        />

        <FeedbackSectionWrapper />

        <InviteTripMateSheet
          open={showInviteFreindSheet}
          onDismiss={() => setShowInviteFreindSheet(false)}
        />

        {selectedEditDestination && (
          <EditDestinationSheet
            onDismiss={() => setSelectedEditDestination(null)}
            selectedDestination={selectedEditDestination}
            open={selectedEditDestination !== null}
          />
        )}

        <SelectDays />

        <MapView className="px-default" />

        <DestinationList
          onSelectEditDestination={handleSelectEditDestination}
        />

        <DisplayForTripOwnerSection />
      </div>
    </div>
  );
}

export default ItineraryPage;

import { Stack } from "@mui/material";
import React, { FC } from "react";

export type Props = {
  weekDays: string[];
};

function translateToThaiDay(day: string) {
  switch (day) {
    case "Monday":
      return "วันจันทร์";
    case "Tuesday":
      return "วันอังคาร";
    case "Wednesday":
      return "วันพุธ";
    case "Thursday":
      return "วันพฤหัสบดี";
    case "Friday":
      return "วันศุกร์";
    case "Saturday":
      return "วันเสาร์";
    case "Sunday":
      return "วันอาทิตย์";
    default:
      return day;
  }
}

function translateToThaiTime(time: string) {
  switch (time) {
    case "Closed":
      return "ปิด";
    default:
      return time;
  }
}

const DestinationWeekDay: FC<Props> = ({ weekDays }) => {
  return (
    <Stack spacing={0.5}>
      {weekDays ? (
        weekDays.length > 0 ? (
          weekDays.map((day) => {
            const [dayTitle, ...rest] = day.split(":");
            return (
              <p key={day} className="text-black text-left">
                <span className="font-bold">
                  {translateToThaiDay(dayTitle)}
                </span>{" "}
                : {translateToThaiTime(rest.join(":").trim())}
              </p>
            );
          })
        ) : (
          <p className="text-gray-400 font-bold text-center">ไม่มีข้อมูล</p>
        )
      ) : (
        <p className="text-gray-400 font-bold text-center">ไม่มีข้อมูล</p>
      )}
    </Stack>
  );
};

export default DestinationWeekDay;

import { Stack } from "@mui/material";
import { Card, CardBody } from "@nextui-org/react";
import classNames from "classnames";
import React, { ChangeEvent, FC } from "react";
import { useUser } from "../../hooks/useUser";
import { CustomStyle } from "../../models/component.model";
import { countries } from "../../models/countries";
import Icon, { Icons } from "../common/Icon";
import RequestCityCard from "../common/RequestCityCard";

type Props = {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
};

function doesCityExist(city: string) {
  return countries.includes(city);
}

const SearchDestinations: FC<CustomStyle<Props>> = ({
  searchQuery,
  className,
  setSearchQuery,
}) => {
  const containerClassname = classNames(
    "relative translate-y-[-50%] z-[33]",
    className
  );

  const isShowCities = Boolean(searchQuery) && !doesCityExist(searchQuery);

  const { user } = useUser();

  return (
    <>
      <section className={containerClassname}>
        <Card className="shadow-lg">
          <CardBody className="p-0 px-4 h-[50px]">
            <Stack
              direction={"row"}
              className="w-full my-auto"
              alignItems={"center"}
              gap={2}
            >
              <div className="flex w-full items-center">
                <Icon name={Icons.magnifyingGlass} size={16} />
                <input
                  className="w-full ml-2 text-sm bg-white outline-none"
                  //   onClick={() => {
                  //     track("clicked_input_search_destinations", {
                  //       userId: user?.id ?? "guest",
                  //       timestamp: new Date().toISOString(),
                  //     });
                  //   }}
                  value={searchQuery}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchQuery(e.target.value);
                  }}
                  placeholder="อยากไปไหนพิมพ์เลย (เช่น Bangkok, Tokyo)"
                />
              </div>
            </Stack>
          </CardBody>
        </Card>
        {isShowCities ? (
          <RequestCityCard
            searchQuery={searchQuery}
            userId={user?.id}
            onSearchQueryChange={setSearchQuery}
          />
        ) : null}
      </section>
    </>
  );
};

export default SearchDestinations;

import { User } from "@supabase/supabase-js";
import classNames from "classnames";
import React, { FC, useState } from "react";
import { CustomStyle } from "../../../models/component.model";
import { Logo } from "../../Logo";
import Hamburger from "../../common/Hamburger";
import UserProfile from "../../common/UserProfile";
import SideBar from "../../common/sidebar/SideBar";

type Props = {
  user: User | null;
};

const NavBar: FC<CustomStyle<Props>> = ({ user, className }) => {
  const containerClassNames = classNames(
    "flex items-center justify-between h-[40px] w-full text-black",
    className
  );
  const [showUserProfile, setShowUserProfile] = useState(false);

  return (
    <nav id="cy-nav-bar" className={containerClassNames}>
      <SideBar
        isShow={showUserProfile}
        onClose={() => setShowUserProfile(false)}
        onOpen={() => setShowUserProfile(true)}
      />
      <Hamburger className="z-[10]" onClick={() => setShowUserProfile(true)} />
      <Logo size="small" />
      <UserProfile user={user} />
    </nav>
  );
};

export default NavBar;

import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 49,
    rowGap: 3,
    width: "100%",
  },
  title: {
    fontSize: 14,
    color: "#8A8A8A",
  },
  upperContainer: {
    position: "relative",
  },
  logo: {
    width: 200,
    height: 50,
    objectFit: "contain",
    backgroundColor: "#F7F7F9"
  },
});

const Header = () => {
  return (
    <View style={styles.container}>
      <View style={styles.upperContainer}>
        <Image style={styles.logo} src={"/images/itinerary-pdf/itinerary-pdf-logo.jpg"} />
      </View>
      <Text style={styles.title}>Eleplan.ai</Text>
    </View>
  );
};

export default Header;

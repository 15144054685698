import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { ItineraryPageProvider } from "../contexts/ItineraryPageContext";
import SignInPage from "../routes/(auth)/sign-in/SignInPage";
import SignUp from "../routes/(auth)/sign-up/SignUp";
import ConfirmDestinationsPage from "../routes/confirm-destinations/ConfirmDestinationsPage";
import CreateItineraryPage from "../routes/create-itinerary/CreateItineraryPage";
import HomePage from "../routes/home/HomePage";
import ItineraryInformationPage from "../routes/itinerary-information/ItineraryInformationPage";
import ItineraryPage from "../routes/itinerary/ItineraryPage";
import PastTripsPage from "../routes/past-trips/PastTripsPage";
import PricingPage from "../routes/pricing/PricingPage";
import GoogleSearch from "../routes/test/GoogleSearch";
import TermsOfServicePage from "../routes/(policies)/terms-of-service/TermsOfServicePage";
import PrivacyPolicyPage from "../routes/(policies)/privacy/PrivacyPolicyPage";
import PresetItineraryPage from "../routes/preset-itinerary/PresetItineraryPage";
import SaveItineraryPage from "../routes/save-itinerary/SaveItineraryPage";
import UserProtectedRouteProvider from "../contexts/UserProtectedRouteContext";
import SignInWithEmailPage from "../routes/(auth)/sign-in/email/SignInWithEmailPage";
import PreventLineUserRoute from "../components/common/PreventLineUserRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <HomePage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-in",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <SignInPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-in/email",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <SignInWithEmailPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-up",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <SignUp />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/test/google-search",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <GoogleSearch />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/itinerary-information",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <ItineraryInformationPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/create-itinerary",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <CreateItineraryPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/confirm-destinations",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <ConfirmDestinationsPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/itinerary",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <UserProtectedRouteProvider>
            <ItineraryPageProvider>
              <ItineraryPage />
            </ItineraryPageProvider>
          </UserProtectedRouteProvider>
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/pricing",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <PricingPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/past-trips",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <PastTripsPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/policies/terms-of-service",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <TermsOfServicePage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/policies/privacy",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <PrivacyPolicyPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/preset-itinerary/:presetId",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <PresetItineraryPage />
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
  {
    path: "/save-itinerary",
    element: (
      <ErrorBoundary>
        <PreventLineUserRoute>
          <UserProtectedRouteProvider>
            <SaveItineraryPage />
          </UserProtectedRouteProvider>
        </PreventLineUserRoute>
      </ErrorBoundary>
    ),
  },
]);

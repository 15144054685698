import { ROLES } from "../../type";
import { SECTION } from "./type";

export function getActivatedSections(
  userRole: ROLES
): { title: string; value: SECTION }[] {
  switch (userRole) {
    case ROLES.TRIP_OWNER:
      return [
        {
          title: "แก้ไข",
          value: "edit",
        },
        {
          title: "รายะเอียด",
          value: "overview",
        },
        {
          title: "วัน-เวลาเปิด",
          value: "openHour",
        },
      ];
    case ROLES.TRIP_MATE:
      return [
        {
          title: "รายะเอียด",
          value: "overview",
        },
        {
          title: "วัน-เวลาเปิด",
          value: "openHour",
        },
      ];
    default:
      return [];
  }
}

export function getDefaultActivatedSection(userRole: ROLES): SECTION {
  switch (userRole) {
    case ROLES.TRIP_OWNER:
      return "edit";
    case ROLES.TRIP_MATE:
      return "overview";
    default:
      return "overview";
  }
}

import { Stack } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import Icon, { Icons } from "../../../../components/common/Icon";
import { getDestinationMapUrl } from "../../../../helper/destination.helper";
import { convertStringToArray } from "../../../../helper/util";
import { translate } from "../../../../libs/utils/lang.util";
import { useFetchDestination } from "../../../../hooks/fetch-data/useFetchDestination";
import LoadingDestination from "./LoadingDestination";

type Props = {
  city: string;
  placeId: string;
  time: string;
};

const DestinationDetail: FC<Props> = ({ city, placeId, time }) => {
  const { data: destination, isFetching } = useFetchDestination(city, placeId);

  if (isFetching) return <LoadingDestination className="h-[90%]" />;

  if (!destination) return <div>Destination not found</div>;

  const { name_place, category } = destination;

  const summaryCategory = convertStringToArray(category ?? "", ",")[0];
  return (
    <div className="flex shadow-button rounded-xl justify-between p-3 w-full h-[90%] bg-white">
      <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
        <Icon name={summaryCategory.toLowerCase() as Icons} size={40} />
        <div className="flex flex-col gap-y-1 justify-between">
          <p className="font-bold line-clamp-1 text-[14px]">{name_place}</p>
          <p className="text-[12px] text-[#8A8A8A] font-semibold">
            {translate(
              "th",
              "destination-category",
              summaryCategory.toLowerCase()
            )}
          </p>
        </div>
      </Stack>
      <div className="flex flex-col gap-y-1 justify-between items-end">
        <p className="font-semibold text-[#8A8A8A] text-[12px]">{time}</p>
        <Link
          className="text-[#EE6222] text-[10px] w-max font-bold"
          to={getDestinationMapUrl(name_place)}
        >
          ดูวิธีเดินทาง
        </Link>
      </div>
    </div>
  );
};

export default DestinationDetail;

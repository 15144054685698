import { Destination } from "../destination";
import { ItineraryDetail } from "../trip.model";

export class ItineraryDetailClass {
  private itineraryDetail: ItineraryDetail;

  constructor(itineraryDetail: ItineraryDetail) {
    this.itineraryDetail = itineraryDetail;
  }

  getDestinations(): Destination[] {
    return Object.values(this.itineraryDetail);
  }

  getDestinationTimes(): string[] {
    return Object.keys(this.itineraryDetail)
  }
}

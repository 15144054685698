import { CalendarDate, getLocalTimeZone } from "@internationalized/date";
import { Empty } from "../../models/misc.model";
import { DATE_FORMAT } from "../../models/enums/date.enum";
import toast from "react-hot-toast";

const DAY = 24 * 60 * 60 * 1000;

export function formatDay(
  originalDayFormat: DATE_FORMAT,
  day: string | Empty,
  separator = "-"
): string {
  if (!day) return "Invalid day";

  let month: string, date: string, year: string;

  const splitDay = day.split(separator);

  switch (originalDayFormat) {
    case "mm-dd-yyyy":
      month = splitDay[1];
      date = splitDay[0];
      year = splitDay[2];
      break;
    case "dd-mm-yyyy":
      return day;
    case "yyyy-mm-dd":
      month = splitDay[2];
      date = splitDay[1];
      year = splitDay[0];
      break;
    default:
      return "Invalid originalDayFormat";
  }

  return `${date}/${month}/${year}`;
}

export function formatTripCardDate(dateFromItinerary: string | Empty): string {
  // Ex : 2024-08-21
  if (!dateFromItinerary) return "Invalid date";
  const [year, month, day] = dateFromItinerary.split("-");
  return `${day}/${month}/${year}`;
}

export function addDaysForTripCardDate(
  dateFromItinerary: string | Empty,
  days: number,
  format: "string" | "date"
): string | Date {
  // Ex : 2024-08-21
  if (typeof dateFromItinerary !== "string") return "Invalid dateFromItinerary";

  if (typeof days !== "number") return "Invalid days";

  const date = new Date(dateFromItinerary);

  date.setDate(date.getDate() + days);

  const newYear = date.getFullYear();

  const newMonth = date.getMonth() + 1;

  const newDay = date.getDate();

  return format === "string" ? `${newDay}/${newMonth}/${newYear}` : date;
}

export function getTimeDifferenceForTripCard(
  dateFromItinerary: string | Empty,
  targetDate: Date
): number {
  if (!dateFromItinerary) {
    console.error("Invalid dateFromItinerary");
    return 0;
  }

  const date = new Date(dateFromItinerary);

  const difference = date.getTime() - targetDate.getTime();

  const daysDifference = Math.floor(difference / DAY);

  return daysDifference;
}

export function getTimeDifferenceInternationalTimes(
  startDate: CalendarDate,
  endDate: CalendarDate
) {
  const difference =
    endDate.toDate(getLocalTimeZone()).getTime() -
    startDate.toDate(getLocalTimeZone()).getTime();
  const daysDifference = Math.floor(difference / DAY);
  return daysDifference;
}

export function isToday({
  day,
  month,
  year,
}: {
  day: number;
  month: number;
  year: number;
}) {
  const today = new Date();

  return (
    today.getDate() === day &&
    today.getMonth() + 1 === month &&
    today.getFullYear() === year
  );
}

export function isInThePast({
  day,
  month,
  year,
}: {
  day: number;
  month: number;
  year: number;
}) {
  const today = new Date();

  const targetDate = new Date(year, month - 1, day + 1);

  return targetDate < today;
}

export function getCalendarDate({
  year,
  month,
  day,
}: {
  year: number;
  month: number;
  day: number;
}) {
  return new CalendarDate(year, month, day);
}

export function translateToThaiMonth(englishMonth: string) {
  switch (englishMonth) {
    case "Jan":
      return "มกราคม";
    case "Feb":
      return "กุมภาพันธ์";
    case "Mar":
      return "มีนาคม";
    case "Apr":
      return "เมษายน";
    case "May":
      return "พฤษภาคม";
    case "Jun":
      return "มิถุนายน";
    case "Jul":
      return "กรกฎาคม";
    case "Aug":
      return "สิงหาคม";
    case "Sep":
      return "กันยายน";
    case "Oct":
      return "ตุลาคม";
    case "Nov":
      return "พฤศจิกายน";
    case "Dec":
      return "ธันวาคม";
    default:
      return "Invalid month";
  }
}

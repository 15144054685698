import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@nextui-org/react";
import Link from "next/link";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { z } from "zod";
import { Logo } from "../../../components/Logo";
import OAuthButton from "../../../components/common/OAuthButton";
import MyInput from "../../../components/forms/MyInput";
import { signUp } from "../../../libs/services/auth.service";
import BreakLine from "../sign-in/_components/BreakLine";

const FormSchema = z.object({
  email: z
    .string({ message: "Please enter your email" })
    .email("Invalid email"),
  password: z
    .string({ message: "Pleae enter your password" })
    .min(6, { message: "Password must be at least 6 characters long" }),
});

type FormFields = z.infer<typeof FormSchema>;

export const SignInWithEmailPage = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: zodResolver(FormSchema),
  });

  const [showPassword, setShowPassword] = useState(false);

  function togglePasswordVisibility() {
    setShowPassword((prev) => !prev);
  }

  async function handleSignUp(data: FormFields) {
    try {
      const { email, password } = data;

      await signUp({ email, password });

      toast.success("กรุณาตรวจสอบ link ยืนยันตัวตนใน email ของคุณ", {
        duration: 3000,
      });
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  return (
    <section className="flex flex-col gap-y-10 justify-center items-center h-full relative w-full lg:w-1/2 px-default bg-plain">
      <header className="flex flex-col gap-y-2 lg:gap-y-5 items-center mt-auto">
        <div className="flex items-center gap-x-2">
          <h1 className="text-[24px] font-bold">สวัสดีเราคือ</h1>
          <Logo className="translate-y-[-2px]" />
        </div>
        <h3 className="text-[24px] font-bold">เพื่อนร่วมทางใหม่ของคุณ</h3>
      </header>
      <form
        onSubmit={handleSubmit(handleSignUp)}
        className="flex flex-col items-end gap-y-5 w-full "
      >
        <MyInput
          label="อีเมล"
          {...register("email")}
          placeholder="กรอกอีเมลของคุณ"
          errorMessage={errors.email?.message}
        />
        <MyInput
          showPassword={showPassword}
          onTogglePasswordVisibility={togglePasswordVisibility}
          label="รหัสผ่าน"
          {...register("password")}
          placeholder="กรอกรหัสผ่านของคุณ"
          type="password"
          errorMessage={errors.password?.message}
        />

        <Button
          size="lg"
          color="primary"
          variant="solid"
          type="submit"
          fullWidth
        >
          สมัครสมาชิก
        </Button>
        <BreakLine />
        <OAuthButton version="outline" provider="google" />
      </form>
      <Link className="mb-default mt-auto" onClick={() => {}} href="/sign-up">
        <p className="text-sm">
          มีบัญชีอยู่แล้วใช่หรือไม่?{" "}
          <span className="font-bold text-black">เข้าสู่ระบบ</span>
        </p>
      </Link>
    </section>
  );
};

export default SignInWithEmailPage;

import { Stack } from "@mui/material";
import React, { FC } from "react";
import { CustomStyle, Parent } from "../../../models/component.model";
import { twMerge } from "tailwind-merge";

const BottomSheetContainer: FC<CustomStyle<Parent>> = ({
  className,
  children,
}) => {
  const containerClassNames = twMerge(
    "text-black p-bottom-sheet min-h-[450px] flex flex-col justify-between",
    className
  );
  return (
    <Stack className={containerClassNames} spacing={2}>
      {children}
    </Stack>
  );
};

export default BottomSheetContainer;

import { CardContent, Stack } from "@mui/material";
import { Button, Card, CardBody } from "@nextui-org/react";
import React, { FC } from "react";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router";
import { CustomStyle } from "../../../models/component.model";
import { Destination } from "../../../models/destination";
import SuggestedDestination from "./SuggestedDestination";
import LoadingDestinations from "./LoadingDestinations";
import AddDestinationSheet from "./add-destination-sheet/AddDestinationSheet";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import { addDestination } from "../../../helper/itinerary.helper";
import toast from "react-hot-toast";
import { updateTripById } from "../../../libs/services/trip.service";

type Props = {
  destinations: Destination[];
  currentDay: string;
  isPremiumUser: boolean;
  isError: boolean;
  isFetching: boolean;
  isRefetching: boolean;
  city: string;
};

const SuggestedDestinations: FC<CustomStyle<Props>> = ({
  destinations,
  currentDay,
  isPremiumUser,
  isError,
  isFetching,
  isRefetching,
  city,
  className,
}) => {
  const navigate = useNavigate();
  const {
    itinerary,
    selectedSuggestedDestination,
    handleUpdateItinerary,
    tripId,
    setSelectedSuggestedDestination,
  } = useItineraryPageContext((selector) => ({
    itinerary: selector!.itinerary,
    selectedSuggestedDestination: selector!.selectedSuggestedDestination,
    handleUpdateItinerary: selector!.handleUpdateItinerary,
    tripId: selector!.tripId,
    setSelectedSuggestedDestination: selector!.setSelectedSuggestedDestination,
  }));

  const isLoading = isFetching || isRefetching;

  async function handleAddDestination() {
    try {
      if (!itinerary) throw new Error("Itinerary is not found");

      if (!selectedSuggestedDestination)
        throw new Error("Please select a destination");

      if (!currentDay) throw new Error("Please select a day");

      const updatedItinerary = addDestination(
        itinerary.itinerary,
        selectedSuggestedDestination,
        currentDay
      );

      toast.loading("กำลังเพิ่มสถานที่...");

      await updateTripById(tripId as string, updatedItinerary);

      handleUpdateItinerary(updatedItinerary);

      toast.dismiss();

      toast.success("เพิ่มสถานที่สำเร็จ");

      setSelectedSuggestedDestination(null);

      navigate(0);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      {selectedSuggestedDestination && (
        <AddDestinationSheet
          destination={selectedSuggestedDestination}
          onAddDestination={handleAddDestination}
          onDismiss={() => setSelectedSuggestedDestination(null)}
          open={Boolean(selectedSuggestedDestination)}
        />
      )}
      <div className={className}>
        {isError ? (
          <h3>Could not suggest your more destinations</h3>
        ) : (
          <>
            {isLoading ? (
              <div className="flex gap-x-3 w-full overflow-x-scroll">
                {Array.from({ length: 5 }, (_, index) => {
                  return <LoadingDestinations key={index} />;
                })}
              </div>
            ) : isPremiumUser ? (
              <div className="flex flex-col gap-y-4 my-2">
                {destinations
                  ? destinations.map((destination: Destination) => {
                      return (
                        <SuggestedDestination
                          key={`${destination.place_id}`}
                          currentDay={currentDay}
                          destination={destination}
                          city={city}
                        />
                      );
                    })
                  : null}
              </div>
            ) : (
              <Card className="m-3">
                <CardContent>
                  <CardBody>
                    <Stack alignItems={"center"} gap={2}>
                      <h1 className="text-sm text-center font-semibold">
                        Unlock premium to use this feature
                      </h1>
                      <Button
                        onClick={() => navigate("/pricing")}
                        color="primary"
                        isIconOnly
                      >
                        <FaLock />
                      </Button>
                    </Stack>
                  </CardBody>
                </CardContent>
              </Card>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SuggestedDestinations;

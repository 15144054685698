import React, { FC } from "react";
import { formatDestinationDescription } from "../../../helper/destination.helper";
import { Empty } from "../../../models/misc.model";

type Props = {
  description: string | Empty;
};

const DestinationDescription: FC<Props> = ({ description }) => {
  const formattedDescription = formatDestinationDescription(description ?? "");
  return (
    <div
      data-body-scroll-lock-ignore
      className="text-[#A09EAB] font-semibold max-h-[300px] overflow-y-scroll"
    >
      {description ? (
        formattedDescription.length > 0 ? (
          formattedDescription.map((desc, index) => (
            <p key={index} className="text-gray-400 text-sm h-full">
              {desc}
            </p>
          ))
        ) : (
          <p className="text-gray-400 text-sm font-bold text-center my-5">
            ไม่มีข้อมูล
          </p>
        )
      ) : (
        <p className="text-gray-400 text-sm font-bold text-center my-5">
          ไม่มีข้อมูล
        </p>
      )}
    </div>
  );
};

export default DestinationDescription;

import React, { useMemo } from "react";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import { getTotalDaysForDayCards } from "../helper";
import DayCard from "./DayCard";

const SelectDays = () => {
  const itinerary = useItineraryPageContext((selector) => selector!.itinerary);

  const currentSelectedDay = useItineraryPageContext(
    (selector) => selector!.currentSelectedDay
  );
  const setCurrentSelectedDay = useItineraryPageContext(
    (selector) => selector!.setCurrentSelectedDay
  );

  if (!itinerary) return null;

  const { date, duration } = itinerary;

  if (!date) throw new Error("The itinerary does not have a date");

  const totalDays = getTotalDaysForDayCards(Number(duration), date);

  return (
    <section className="flex pl-default gap-x-5 overflow-y-hidden overflow-x-scroll min-h-[100px] p-3">
      {totalDays.map(({ title, date }, index) => (
        <DayCard
          key={title}
          onClick={() => setCurrentSelectedDay(`Day${index + 1}`)}
          dayTitle={title}
          date={date}
          isSelected={currentSelectedDay === `Day${index + 1}`}
        />
      ))}
    </section>
  );
};

export default SelectDays;

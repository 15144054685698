import { StyleSheet, View } from "@react-pdf/renderer";
import React, { FC } from "react";
import { Parent } from "../../../models/component.model";

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    rowGap: 10,
    width: "100%",
  },
});

const VStack: FC<Parent> = ({ children }) => {
  return <View style={styles.container}>{children}</View>;
};

export default VStack;

import { MapIcon } from "@heroicons/react/24/outline";
import { Button } from "@nextui-org/react";
import React, { FC, useState } from "react";
import { BottomSheet, BottomSheetProps } from "react-spring-bottom-sheet";
import DestinationCategories from "../../../../components/common/DestinationCategories";
import Rating from "../../../../components/common/Rating";
import ActivatedSection from "../../../../components/specific/bottom-sheet/activated-section/ActivatedSection";
import BottomSheetContainer from "../../../../components/specific/bottom-sheet/BottomSheetContainer";
import BottomSheetNavbar from "../../../../components/specific/bottom-sheet/BottomSheetNavbar";
import {
  formatDestinationDescription,
  getDestinationMapUrl,
} from "../../../../helper/destination.helper";
import { convertStringToArray } from "../../../../helper/util";
import { Destination } from "../../../../models/destination";
import DestinationDescription from "../../../../components/specific/destination/DestinationDescription";
import { Stack } from "@mui/material";
import DestinationWeekDay from "../../../../components/specific/destination/DestinationWeekDay";

type Props = {
  destination: Destination;
  onAddDestination: () => void;
};

type SECTION = "overview" | "openHour";

const ALL_SECTIONS: { title: string; value: SECTION }[] = [
  {
    title: "รายละเอียด",
    value: "overview",
  },
  {
    title: "เวลาเปิด-ปิด",
    value: "openHour",
  },
];

const AddDestinationSheet: FC<Props & Omit<BottomSheetProps, "children">> = ({
  destination,
  onAddDestination,
  ...bottomSheetProps
}) => {
  const { name_place, category, rating, DCT, operating_hours } = destination;

  const [showingSection, setShowingSection] = useState<SECTION>("overview");

  return (
    <BottomSheet {...bottomSheetProps}>
      <BottomSheetContainer>
        <Stack spacing={2}>
          <BottomSheetNavbar>
            <h1 className="text-[18px] font-bold line-clamp-1">{name_place}</h1>
            <MapIcon
              onClick={() => {
                window.location.assign(getDestinationMapUrl(name_place));
              }}
              className="size-6"
            />
          </BottomSheetNavbar>

          <div className="flex justify-between">
            <DestinationCategories
              categories={convertStringToArray(category, ",")}
            />
            <Rating rating={rating || 0} />
          </div>

          <ActivatedSection<SECTION>
            showingSection={showingSection}
            sectionSetter={setShowingSection}
            sections={ALL_SECTIONS}
          />

          {showingSection === "overview" && (
            <DestinationDescription description={DCT} />
          )}

          {showingSection === "openHour" && (
            <DestinationWeekDay
              weekDays={operating_hours?.weekday_text || []}
            />
          )}
        </Stack>

        <Button
          onClick={onAddDestination}
          className="font-bold"
          fullWidth
          color="primary"
        >
          เพิ่มสถานที่เที่ยว
        </Button>
      </BottomSheetContainer>
    </BottomSheet>
  );
};

export default AddDestinationSheet;

import React, { FC } from "react";
import { IoPersonAddOutline } from "react-icons/io5";
import { CustomStyle, TButton } from "../../../models/component.model";
import classNames from "classnames";

const InviteFriendButton: FC<CustomStyle<TButton>> = ({
  className,
  onClick,
}) => {
  const containerClassNames = classNames("text-[#EE6222]", className);
  return (
    <IoPersonAddOutline
      onClick={onClick}
      size={24}
      className={containerClassNames}
    />
  );
};

export default InviteFriendButton;

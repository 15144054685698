import classNames from "classnames";
import React, { FC } from "react";
import { CustomStyle } from "../models/component.model";
import { useNavigate } from "react-router";

type LogoProps = {
  size?: "small" | "medium";
};

function getCircleSize(size: LogoProps["size"] = "medium"): number {
  return size === "medium" ? 45 : 30;
}

export const Logo: FC<CustomStyle<LogoProps>> = ({
  className,
  size = "medium",
}) => {
  const navigate = useNavigate();

  const containerClassname = classNames(
    "flex items-center justify-center relative text-black relative w-max h-max",
    className
  );
  const circleClassname = classNames(
    "rounded-full bg-primary absolute right-0 translate-x-5 translate-y-[-5px]",
    `w-[${getCircleSize(size)}px] h-[${getCircleSize(size)}px]`
  );
  const titleClassname = classNames("font-nunito font-extrabold z-[30]", {
    "text-2xl": size === "medium",
    "text-xl": size === "small",
  });
  return (
    <div
      onClick={() => {
        navigate("/");
      }}
      className={containerClassname}
    >
      <div className={circleClassname}></div>
      <h1 className={titleClassname}>Unitrip.ai</h1>
    </div>
  );
};

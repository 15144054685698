import React, { FC } from "react";
import { Destination } from "../../../components/itinerary/destination/Destination";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import { Destination as DestinationType } from "../../../models/destination";

type Props = {
  destination: DestinationType;
  currentDay: string;
  city: string;
};

const SuggestedDestination: FC<Props> = ({ destination, currentDay, city }) => {
  const setSelectedSuggestedDestination = useItineraryPageContext(
    (selector) => selector!.setSelectedSuggestedDestination
  );

  return (
    <Destination
      country={city}
      destination={destination}
      onClick={() => setSelectedSuggestedDestination(destination)}
    />
  );
};

export default SuggestedDestination;

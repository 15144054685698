import { supabase } from "../../configs/db.config";

export async function storeResetPasswordToken(token: {
  id: string;
  token: string;
  expires_at: Date;
  used: boolean;
}) {
  try {
    const { data, error } = await supabase
      .from("reset_password_tokens")
      .insert(token)
      .select()
      .single();

    if (error) throw new Error(error.message);

    return data as {
      id: string;
      token: string;
      expires_at: Date;
      used: boolean;
    };
  } catch (error: any) {
    throw new Error(error.message);
  }
}

import { TranslateContents } from "../../models/languages.model";

export const LANGUAGES = ["en", "th"] as const;

export const TRANSLATE_SECTIONS = ["destination-category"] as const;

export const TRANSLATE_CONTENTS: TranslateContents = {
  "destination-category": {
    highlight: {
      en: "Highlight",
      th: "ไฮไลต์ของเมืองนี้",
    },
    history: {
      en: "History",
      th: "ประวัติ",
    },
    local: {
      en: "Local",
      th: "โลคอล&ออริจินอล",
    },
    nature: {
      en: "Nature",
      th: "ธรรมชาติ",
    },
    event: {
      en: "Event",
      th: "อีเว้นท์",
    },
    traditional: {
      en: "Traditional",
      th: "ด้านวัฒนธรรม",
    },
    activity: {
      en: "Activity",
      th: "กิจกรรม",
    },
  },
};

import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC } from "react";
import { Drawer } from "../../../models/component.model";

const BackDrop: FC<Pick<Drawer, "onClose">> = ({ onClose }) => {
  const backdropClassNames = classNames(
    "bg-black w-full h-full absolute top-0 left-0 transition-all z-[99]"
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: "50%" }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      onClick={onClose}
      className={backdropClassNames}
    ></motion.div>
  );
};

export default BackDrop;

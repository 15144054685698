import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { FC, useMemo } from "react";
import { Destination } from "../../../../../../../models/destination";

type Props = {
  destination: Destination;
  time: string;
  isLast: boolean;
};

const Destination: FC<Props> = ({ destination, time, isLast }) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: isLast ? 0 : "1px solid #EFEFEF",
        height: 40,
      },
      timeCell: {
        width: "15%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 5,
        borderRight: "1px solid #EFEFEF",
      },
      namePlaceCell: {
        width: "85%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: 5,
      },
    });
  }, [isLast]);
  return (
    <View style={styles.container}>
      <View style={styles.timeCell}>
        <Text style={{ fontSize: 12 }}>{time}</Text>
      </View>
      <View style={styles.namePlaceCell}>
        <Text style={{ fontSize: 12 }}>{destination.name_place}</Text>
      </View>
    </View>
  );
};

export default Destination;

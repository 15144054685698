import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems : "center",
    width: "100%",
    borderBottom: "1px solid #EFEFEF",
  },
  timeCell: {
    width: "15%",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    borderRight: "1px solid #EFEFEF",
    height: 40,
  },
  destinationCell: {
    width: "85%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 5,
  },
  destinationText: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
  },
});

const TableHeader = () => {
  return (
    <View style={styles.container}>
      <View style={styles.timeCell}>
        <Text style={{ fontSize: 12, fontWeight: "bold" }}>Time</Text>
      </View>
      <View style={styles.destinationCell}>
        <Text style={styles.destinationText}>Destination</Text>
      </View>
    </View>
  );
};

export default TableHeader;

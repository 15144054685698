import { Stack } from "@mui/material";
import React, { FC } from "react";
import TripMate from "./TripMate";

type Props = {
  emails: string[];
  onRemoveEmail: (email: string) => void;
};

const TripMateList: FC<Props> = ({ emails, onRemoveEmail }) => {
  return (
    <Stack>
      {emails.map((email) => (
        <TripMate onRemoveEmail={onRemoveEmail} key={email} email={email} />
      ))}
    </Stack>
  );
};

export default TripMateList;

import classNames from "classnames";
import React, { ChangeEvent, FC, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { MdCancel } from "react-icons/md";
import { useSearchGooglePlaces } from "../hooks/mutation/useSearchGooglePlaces";
import { useDebounce } from "../hooks/useDebounce";
import { CustomStyle } from "../models/component.model";
import { Destination } from "../models/destination";
import Icon, { Icons } from "./common/Icon";
import { usePreventKeyboardPushingScreen } from "../hooks/usePreventKeyboardPushingScreen";

type Props = {
  onAddDestination: any;
  currentDay: string;
  targetCountry: string;
};

const FETCH_DELAY = 1000;

export const GoogleDestinationSearchBar: FC<CustomStyle<Props>> = ({
  onAddDestination,
  currentDay,
  targetCountry,
  className,
}) => {
  const containerClassNames = classNames("flex flex-col relative", className);

  const inputRef = useRef<HTMLInputElement>(null);

  usePreventKeyboardPushingScreen(inputRef);

  const [query, setQuery] = useState("");

  const {
    mutate: searchGooglePlaces,
    data: places,
    isPending: loading,
  } = useSearchGooglePlaces({
    query,
    country: targetCountry,
  });

  function handleSearchGooglePlaces() {
    if (!query) return;
    try {
      searchGooglePlaces();
    } catch (error: any) {
      toast.error("Failed while searching for places");
    }
  }

  useDebounce(handleSearchGooglePlaces, FETCH_DELAY, [query]);

  function handleClearQuery() {
    setQuery("");
  }

  return (
    <div className={containerClassNames}>
      <div className="flex items-center gap-x-2 shadow-button h-[50px] px-3 rounded-xl bg-white">
        <Icon name={Icons.magnifyingGlass} size={16} />
        <input
          ref={inputRef}
          placeholder="ชื่อสถานที่ (ภาษาอังกฤษ)"
          className="border-none outline-none w-full bg-transparent"
          type="text"
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setQuery(e.target.value)
          }
        />
        {query && (
          <MdCancel
            onClick={handleClearQuery}
            className="text-primary hover:scale-105"
            size={25}
          />
        )}
      </div>
      {loading ? (
        <ul className="flex gap-x-3 justify-center w-full rounded-xl bg-white shadow-md p-5 mt-5">
          <span className="animate-spin h-6 w-6 rounded-full border-[3px] border-b-primary"></span>
          <p>Loading places...</p>
        </ul>
      ) : null}
      {places && (
        <ul className="flex flex-col m-0 w-full translate-y-16 shadow-md rounded-xl absolute bg-white overflow-hidden z-[1000]">
          {places.map((place) => (
            <PlaceItem
              key={place.id}
              onClearQuery={handleClearQuery}
              currentDay={currentDay}
              onAddDestination={onAddDestination}
              place={place}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const PlaceItem: FC<{
  place: Destination;
  onAddDestination: any;
  currentDay: string;
  onClearQuery: any;
}> = ({ place, onAddDestination, currentDay, onClearQuery }) => {
  const [hover, setHover] = useState(false);

  return (
    <li
      onClick={() => {
        onAddDestination(place, currentDay);
        onClearQuery();
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-full cursor-pointer p-4 ${
        hover ? "bg-primary text-white" : "bg-white text-black"
      } transition-all`}
    >
      <p className="font-semibold">{place.name_place}</p>
    </li>
  );
};

import React, { FC } from "react";
import { Parent } from "../../../models/component.model";

const BottomSheetNavbar: FC<Parent> = ({ children }) => {
  return (
    <nav className="flex justify-between items-center gap-x-2">{children}</nav>
  );
};

export default BottomSheetNavbar;

import { isAxiosError } from "axios";
import { Empty } from "../models/misc.model";
import { MONTHS } from "../models/enums/date.enum";

export function splitSentences(
  text: string,
  criteriaPuntucations: string[]
): Record<string, string> {
  const sentences = text.split(
    new RegExp(`([${criteriaPuntucations.join("")}])`)
  );

  // Example of the result
  // {"Today is Month" : "."}
  // Record<setence | punctuation>

  let result: Record<string, string> = {};

  for (let index = 0; index < sentences.length; index++) {
    result[sentences[index]] = sentences[index + 1] as string;
  }

  return removeEmptyKeysOrValues(
    removeMatchedKeyFromObject(result, criteriaPuntucations)
  );
}

function removeMatchedKeyFromObject(
  object: Record<any, any>,
  criterias: string[]
) {
  return Object.entries(object).reduce(
    (acc, [key, value]) => {
      if (!criterias.includes(key)) {
        acc[key.trim()] = value ?? "";
      }

      return acc;
    },
    {} as Record<any, any>
  );
}

export function removeEmptyItemsFromArray<D>(array: D[]): D[] {
  return array.filter((item) => Boolean(item));
}

function removeEmptyKeysOrValues(
  obj: Record<string, string>
): Record<string, string> {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (key && value) {
        acc[key] = value;
      }

      return acc;
    },
    {} as Record<string, string>
  );
}

// text is Hello, how are you doing?
// symbol is , and ?
export function doesSymbolExistInSentence(
  text: string,
  symbols: string[]
): boolean {
  return symbols.some((symbol) => text.includes(symbol));
}

export function formatDestinationWeekText(weekdayText: string): string[] {
  let day: string = "",
    time: string = "";

  const colonIndex = weekdayText.indexOf(":");
  day = weekdayText.slice(0, colonIndex);
  time = weekdayText.slice(colonIndex + 1);

  return [day, time];
}

export function getOccurrence(
  text: string | Empty,
  symbol: string | Empty
): number {
  if (!text || !symbol) throw new TypeError("Text and symbol are required");

  if (typeof text !== "string" || typeof symbol !== "string")
    throw new TypeError("Text and symbol must be string");

  return text.split(symbol).length - 1;
}

export function formatCity(city: string | Empty): string {
  if (!city) throw new TypeError("City is required");

  if (!city.includes(",")) throw new Error("City must contain comma");

  if (getOccurrence(city, ",") > 1)
    throw new Error("City must contain at most one comma");

  if (getOccurrence(city, ", ") <= 0) throw new Error("City must contain , ");

  return city.replace(", ", "_");
}

export function getErrorMessage(error: unknown): string {
  return isAxiosError(error)
    ? error.response?.data.message
    : (error as Error).message;
}

export function convertStringToArray(
  targetString: string | Empty,
  symbol: string
): string[] {
  if (!symbol) throw new TypeError("Symbol is required");

  if (!targetString) return [];

  if (typeof targetString !== "string" || typeof symbol !== "string")
    throw new TypeError("Target string and symbol must be string");

  return targetString.split(symbol);
}

export function padTime(hour: number, minute: number): string[] {
  if (isEmpty(hour) || isEmpty(minute))
    throw TypeError("Hour and minute are required");

  if (hour < 0) throw EvalError("Hour must be positive");

  if (minute < 0) throw EvalError("Minute must be positive");

  if (minute > 59) throw new EvalError("Minute must be less than 60");

  if (hour > 23) throw new EvalError("Hour must be less than 24");

  return [String(hour).padStart(2, "0"), String(minute).padStart(2, "0")];
}

export function extractDestinationTime(
  destinationTime: string | Empty
): string[] | null {
  if (!destinationTime) return null;

  if (typeof destinationTime !== "string")
    throw new TypeError("Destination time must be string");

  if (!destinationTime.includes(":"))
    throw new EvalError("Invalid time format (Should contain :)");

  if (getOccurrence(destinationTime, ":") > 1)
    throw new Error("Invalid time format (Should contain only one :)");

  const [hour, minute] = destinationTime.split(":");

  if (hour.length !== 2 || minute.length !== 2)
    throw new Error("Invalid time format (Should be HH:MM)");

  return [hour, minute];
}

export function isEmpty(value: unknown): boolean {
  return value === null || value === undefined;
}

export function secondsToMilliseconds(seconds: number): number {
  if (seconds < 0) throw new Error("Seconds must be positive");

  return seconds * 1000;
}

export function getLocalStorageItem<T>(key: string): T | null {
  if (!key) throw new TypeError("Key is required");

  return JSON.parse(localStorage.getItem(key) as string);
}

export function setLocalStorageItem<T>(key: string, value: T): void {
  if (!key) throw new TypeError("Key is required");

  localStorage.setItem(key, JSON.stringify(value));
}

export function extractTripDate(date: string): {
  month: number;
  day: number;
  year: number;
} {
  const [year, month, day] = date.split("-");

  return {
    month: Number(month),
    day: Number(day),
    year: Number(year),
  };
}

export function convertMonthToText(month: number): string {
  if (month < 1 || month > 12) throw new Error("Invalid month");

  return Object.values(MONTHS)[month - 1];
}

export function generateArray(length: number): never[] {
  return Array.from({ length });
}

export function isInTime(
  targetTime: string,
  currentTime: Date = new Date()
): boolean {
  const [hour, minute] = extractDestinationTime(targetTime) as string[];

  const targetDate = new Date(
    currentTime.getFullYear(),
    currentTime.getMonth(),
    currentTime.getDate(),
    Number(hour),
    Number(minute)
  );

  return currentTime.getTime() >= targetDate.getTime();
}

export function deepCopy<D>(obj: D): D {
  if (!obj) throw new TypeError("Object is required");
  return JSON.parse(JSON.stringify(obj));
}

export function isObjectSame(
  object1: Record<any, any>,
  object2: Record<any, any>
) {
  if (!object1 || !object2) throw new Error("Objects are required");
  return JSON.stringify(object1) === JSON.stringify(object2);
}

export function isEmailFormatValid(email: string): boolean {
  if (!email) throw new TypeError("Email is required");

  if (typeof email !== "string") throw new TypeError("Email must be string");

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export async function withCatch<T>(
  promise: Promise<T>
): Promise<[undefined, T] | [Error]> {
  return promise
    .then((data) => [undefined, data] as [undefined, T])
    .catch((error) => [error]);
}

import { Destination } from "../models/destination";
import { Itinerary } from "../models/trip.model";
import { deepCopy, isObjectSame, padTime } from "./util";

export function updateItineraryTime(
  itinerary: Itinerary,
  day: string,
  originTime: string,
  targetTime: string
) {
  const deepItinerary = deepCopy<Itinerary>(itinerary);

  delete deepItinerary[day][originTime];

  deepItinerary[day][targetTime] = itinerary[day][originTime];

  deepItinerary[day] = Object.fromEntries(
    Object.entries(deepItinerary[day]).sort((first, second) => {
      const timeFirst = first[0];

      const timeSecond = second[0];

      return timeFirst.localeCompare(timeSecond);
    })
  );

  return deepItinerary;
}

export function deleteDestination(
  itinerary: Itinerary,
  targetDay: string,
  targetDestination: Destination
) {
  const deepItinerary = deepCopy(itinerary);

  const targetDayDestinations = deepItinerary[targetDay];

  if (!targetDayDestinations) {
    throw new Error(
      "[ERROR] : No destination found while deleting destination"
    );
  }

  const keyTime = Object.keys(targetDayDestinations).find(
    (key) =>
      targetDayDestinations[key].name_place === targetDestination.name_place
  );

  if (!keyTime) {
    return deepItinerary;
  }

  delete targetDayDestinations[keyTime];

  return deepItinerary;
}

export function addDestination(
  itinerary: Itinerary,
  newDestination: Destination,
  day: string
) {
  const rightNow = new Date();

  const [hour, minute] = [rightNow.getHours(), rightNow.getMinutes()];

  const [formattedHour, formattedMinutes] = padTime(hour, minute);

  let formattedTime = `${formattedHour}:${formattedMinutes}`;

  // If the time is already taken, increment the time by 1 minute
  while (Object.keys(itinerary[day]).includes(formattedTime)) {
    const [h, m] = formattedTime.split(":").map((time) => parseInt(time));
    if (m === 59) {
      formattedTime = `${h + 1}:00`;
    } else {
      formattedTime = `${h}:${m + 1}`;
    }
  }

  const deepItinerary = deepCopy<Itinerary>(itinerary);

  const targetDay = deepItinerary[day];

  const result = {
    ...deepItinerary,
    [day]: {
      ...targetDay,
      [formattedTime]: {
        ...newDestination,
        // special_events:
        //   Object.keys(newDestination).includes("special_events") &&
        //   newDestination["special_events"]
        //     ? newDestination["special_events"].split(",")
        //     : "",
      },
    },
  };
  return result;
}
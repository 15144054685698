import React from "react";
import { useItineraryPageContext } from "../../../contexts/ItineraryPageContext";
import RecommendHeader from "./RecommendHeader";
import { ROLES } from "../type";
import { useFetchSuggestedDestinations } from "../../../hooks/fetch-data/useFetchSuggestedDestinations";
import { GoogleDestinationSearchBar } from "../../../components/GoogleDestinationSearchBar";
import { Destination } from "../../../models/destination";
import toast from "react-hot-toast";
import { addDestination } from "../../../helper/itinerary.helper";
import { updateTripById } from "../../../libs/services/trip.service";
import LoadingDestinations from "./LoadingDestinations";
import SuggestedDestinations from "./SuggestedDestinations";
import { addNewDestinationFromGoogleAPI } from "../../../libs/services/destination.service";
import { formatCity, withCatch } from "../../../helper/util";

const DisplayForTripOwnerSection = () => {
  const {
    userRole,
    itinerary,
    currentSelectedDay,
    handleUpdateItinerary,
    tripId,
  } = useItineraryPageContext((selector) => ({
    userRole: selector!.userRole,
    itinerary: selector!.itinerary,
    currentSelectedDay: selector!.currentSelectedDay,
    handleUpdateItinerary: selector!.handleUpdateItinerary,
    tripId: selector!.tripId,
  }));

  const {
    data: suggestedDestinations,
    isFetching: isFetchingSuggestedDestinations,
    isError: isSuggestedDestinationsError,
    refetch: refetchSuggestedDestinations,
    isRefetching: isRefetchingSuggestedDestinations,
  } = useFetchSuggestedDestinations(
    itinerary?.city ?? "",
    itinerary?.categories ?? []
  );

  const tripDetail = currentSelectedDay
    ? (itinerary?.itinerary?.[currentSelectedDay] ?? null)
    : null;

  async function handleAddGoogleDestination(
    destination: Destination,
    currentDay: string
  ) {
    try {
      if (!tripDetail || !itinerary?.itinerary) return;

      if (!destination) return toast.error("Please select a destination");

      if (!currentDay) return toast.error("Please select a day");

      const updatedItinerary = addDestination(
        itinerary.itinerary,
        destination,
        currentDay
      );

      const [error] = await withCatch(
        addNewDestinationFromGoogleAPI(
          formatCity(itinerary.city),
          destination.place_id
        )
      );

      if (error) {
        toast.error("ขอโทษนร้าาา มีปัญหาในการเพิ่มสถานที่");
        return;
      }

      toast.loading("Adding the destination...");

      await updateTripById(tripId as string, updatedItinerary);

      handleUpdateItinerary(updatedItinerary);

      toast.dismiss();

      toast.success("Added the destination");
    } catch (error) {
      toast.error(error.message);
    }
  }
  return (
    userRole === ROLES.TRIP_OWNER && (
      <>
        <RecommendHeader onRefresh={refetchSuggestedDestinations} />

        {itinerary && currentSelectedDay && (
          <GoogleDestinationSearchBar
            className="mx-default"
            targetCountry={itinerary.country}
            currentDay={currentSelectedDay}
            onAddDestination={handleAddGoogleDestination}
          />
        )}

        {currentSelectedDay &&
          tripDetail &&
          (isRefetchingSuggestedDestinations ||
          isFetchingSuggestedDestinations ? (
            <div className="flex gap-x-3 w-full overflow-x-scroll">
              {Array.from({ length: 5 }, (_, index) => {
                return <LoadingDestinations key={index} />;
              })}
            </div>
          ) : (
            <SuggestedDestinations
              className="mx-default"
              destinations={suggestedDestinations}
              currentDay={currentSelectedDay}
              isPremiumUser={true}
              isError={isSuggestedDestinationsError}
              isFetching={isFetchingSuggestedDestinations}
              isRefetching={isRefetchingSuggestedDestinations}
              city={itinerary?.city ?? ""}
            />
          ))}
      </>
    )
  );
};

export default DisplayForTripOwnerSection;

import { Document, Font, Page, StyleSheet } from "@react-pdf/renderer";
import React, { FC } from "react";
import { addDaysForTripCardDate } from "../../../libs/utils/date.util";
import { TripDetail } from "../../../models/trip.model";
import Header from "./_components/Header";
import TripDate from "./_components/TripDate";
import TripDestination from "./_components/TripDestination";
import DestinationList from "./_components/destination-list/DestinationList";

Font.register({
  family: "Nunito",
  src: "/fonts/nunito-bold.ttf",
  fontStyle: "normal",
  fontWeight: 700,
});

type Props = {
  itinerary: TripDetail;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#F7F7F9",
    fontFamily: "Nunito",
    color: "black",
    width: "100%",
    height: "100%",
    padding: 15,
    gap: 10,
    alignItems: "center",
  },
});

const ItineraryPDF: FC<Props> = ({ itinerary }) => {
  const { date, duration, name, itinerary : itineraryDetail } = itinerary;

  if (!itineraryDetail) throw new Error("Itinerary detail is required")

  if (!date) throw new Error("Date is required");

  const tripEndDate = date
    ? addDaysForTripCardDate(date, Number(duration), "string")
    : "-";

  const days = Object.keys(itineraryDetail);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <TripDate date={date} endDate={tripEndDate} />
        <TripDestination name={name} />
        <DestinationList days={days} tripDate={date} tripDetail={itinerary} />
      </Page>
    </Document>
  );
};

export default ItineraryPDF;

import React, { FC, useRef } from "react";
import { useNavigate } from "react-router";
import Carousel from "../../../components/common/Carousel";
import { SwiperSlide } from "swiper/react";
import { Trips } from "../../../models/classes/Trips.class";
import { Trip } from "../../../models/trip.model";
import TripCard from "../../../components/specific/trip-card/TripCard";

type Props = {
  itineraries: Trip[];
  onDeleteItinerary: (tripId: string) => void;
};

const MyTrips: FC<Props> = ({ itineraries, onDeleteItinerary }) => {
  const navigate = useNavigate();

  const trips = new Trips(itineraries);

  const upcomingTrips = trips.filterUpcomingTrips().return();

  if (upcomingTrips.length <= 0) {
    return (
      <p className="text-base my-16 font-medium">ยังไม่มีแผนตอนนี้ 🥲</p>
    );
  }

  return (
    <Carousel>
      {upcomingTrips.map((trip, index) => (
        <SwiperSlide className="h-full" key={trip._id}>
          <TripCard
            index={index}
            onClick={() =>
              navigate(`/itinerary?tripId=${trip._id}&view=itinerary`)
            }
            onDeleteItinerary={() => onDeleteItinerary(trip._id)}
            trip={trip}
          />
        </SwiperSlide>
      ))}
    </Carousel>
  );
};

export default MyTrips;

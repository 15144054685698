import { Card, Skeleton } from "@nextui-org/react";
import React from "react";
import { CustomStyle } from "../../../../models/component.model";
import { twMerge } from "tailwind-merge";

export default function LoadingDestination({ className }: CustomStyle<{}>) {
  const containerClassNames = twMerge("w-full space-x-5 p-3 h-full", className);
  return (
    <Card className={containerClassNames} radius="lg">
      <div className="flex flex-row w-full space-x-3 items-center h-full">
        <Skeleton className="w-1/3 h-full rounded-lg">
          <div className="h-full w-1/3 rounded-lg bg-default-200" />
        </Skeleton>
        <div className="w-full h-full justify-between flex flex-col">
          <Skeleton className="w-full rounded-lg h-3">
            <div className="h-3 w-full rounded-lg bg-default-200" />
          </Skeleton>
          <Skeleton className="w-full rounded-lg h-3">
            <div className="h-3 w-full rounded-lg bg-default-200" />
          </Skeleton>
          <Skeleton className="w-3/5 rounded-lg h-3">
            <div className="h-3 w-3/5 rounded-lg bg-default-300" />
          </Skeleton>
        </div>
      </div>
    </Card>
  );
}

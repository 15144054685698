import { StyleSheet, View } from "@react-pdf/renderer";
import React, { FC } from "react";
import { Parent } from "../../../models/component.model";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
});

const HStack: FC<Parent> = ({ children }) => {
  return <View style={styles.container}>{children}</View>;
};

export default HStack;

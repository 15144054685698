import { countries } from "../../models/countries";

export function validateInput(
  country: string,
  exceedDayLimit: boolean,
  maximumDays: number,
  tripMates: number
): { isError: boolean; message: string } {
  if (!country) return { isError: true, message: "Please select a country" };

  if (!countries.includes(country))
    return { isError: true, message: `${country} is not a valid city` };

  if (exceedDayLimit)
    return {
      isError: true,
      message: `The trip must be less than ${maximumDays} days`,
    };

  if (tripMates < 1)
    return { isError: true, message: "Please select at least one trip mate" };

  return { isError: false, message: "" };
}

import React, { FC } from "react";
import PDFCard from "../../pdf/PDFCard";
import { StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  destinationName: {
    fontSize: 12,
    textAlign: "center",
    fontWeight: "bold",
  },
});

type Props = {
  name: string;
};

const TripDestination: FC<Props> = ({ name }) => {
  return (
    <PDFCard>
      <Text style={{ fontWeight: "bold", fontSize: 12, color: "#8A8A8A" }}>
        Destination
      </Text>
      <Text style={styles.destinationName}>{name}</Text>
    </PDFCard>
  );
};

export default TripDestination;

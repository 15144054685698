import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@nextui-org/react";
import Link from "next/link";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { z } from "zod";
import OAuthButton from "../../../../components/common/OAuthButton";
import MyInput from "../../../../components/forms/MyInput";
import { Logo } from "../../../../components/Logo";
import {
  resetPasswordViaEmail,
  signIn,
} from "../../../../libs/services/auth.service";
import { generateResetPasswordToken } from "../../../../libs/token";
import { storeResetPasswordToken } from "../../../../libs/services/resetPasswordToken.service";
import { v4 } from "uuid";
import BreakLine from "../_components/BreakLine";

const FormSchema = z.object({
  email: z
    .string({ message: "Please enter your email" })
    .email("Invalid email"),
  password: z
    .string({ message: "Pleae enter your password" })
    .min(6, { message: "Password must be at least 6 characters long" }),
});

type FormFields = z.infer<typeof FormSchema>;

export const SignInWithEmailPage = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: zodResolver(FormSchema),
  });
  const { email } = watch();

  const [showPassword, setShowPassword] = useState(false);

  function togglePasswordVisibility() {
    setShowPassword((prev) => !prev);
  }

  async function handleLogin(data: FormFields) {
    try {
      const { email, password } = data;

      await signIn({ email, password });

      toast.success("Logged in successfully");

      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  async function handleForgotPassword() {
    try {
      toast.loading("Sending password reset link to your email...");

      if (!email) throw new Error("Please enter your email");

      const { token, expires_at } = await generateResetPasswordToken();

      const { token: storedToken } = await storeResetPasswordToken({
        id: v4(),
        token,
        expires_at,
        used: false,
      });

      const data = await resetPasswordViaEmail(email, storedToken);

      toast.dismiss();

      if (!data) throw new Error("No data returned");

      toast.success(
        `Password reset link has been sent to ${email}. Please check your email.`,
        {
          duration: 3000,
        }
      );
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message);
    }
  }

  return (
    <section className="flex flex-col gap-y-10 justify-center items-center h-full relative w-full lg:w-1/2 px-default bg-plain">
      <header className="flex flex-col gap-y-2 lg:gap-y-5 items-center mt-auto">
        <div className="flex items-center gap-x-2">
          <h1 className="text-[24px] font-bold">สวัสดีเราคือ</h1>
          <Logo className="translate-y-[-2px]" />
        </div>
        <h3 className="text-[24px] font-bold">เพื่อนร่วมทางใหม่ของคุณ</h3>
      </header>
      <form
        onSubmit={handleSubmit(handleLogin)}
        className="flex flex-col items-end gap-y-5 w-full "
      >
        <MyInput
          label="อีเมล"
          {...register("email")}
          placeholder="กรอกอีเมลของคุณ"
          errorMessage={errors.email?.message}
        />
        <MyInput
          showPassword={showPassword}
          onTogglePasswordVisibility={togglePasswordVisibility}
          label="รหัสผ่าน"
          {...register("password")}
          placeholder="กรอกรหัสผ่านของคุณ"
          type="password"
          errorMessage={errors.password?.message}
        />
        <p
          onClick={handleForgotPassword}
          className="text-[0.85rem] cursor-pointer hover:font-semibold transition-all"
        >
          ลืมรหัสผ่าน?
        </p>
        <Button
          size="lg"
          color="primary"
          variant="solid"
          type="submit"
          fullWidth
        >
          มาเริ่มกันเลย
        </Button>
        <BreakLine />
        <OAuthButton version="outline" provider="google" />
      </form>
      <Link className="mb-default mt-auto" onClick={() => {}} href="/sign-up">
        <p className="text-sm">
          ยังไม่มีบัญชีใช่ไหม?{" "}
          <span className="font-bold text-black">สมัครสมาชิก</span>
        </p>
      </Link>
    </section>
  );
};

export default SignInWithEmailPage;

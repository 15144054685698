import React, { FC } from "react";
import PDFCard from "../../pdf/PDFCard";
import { Text } from "@react-pdf/renderer";
import { formatTripCardDate } from "../../../../libs/utils/date.util";

type Props = {
  date: string;
  endDate: string | Date;
};

const TripDate: FC<Props> = ({ date, endDate }) => {
  return (
    <PDFCard>
      <Text>
        <Text style={{ fontWeight: "bold", fontSize: 12 }}>
          <Text style={{ color: "#8A8A8A" }}>Trip Start :</Text>
        </Text>{" "}
        {date ? formatTripCardDate(date) : "-"}
      </Text>
      <Text style={{ fontWeight: "bold", fontSize: 12 }}>
        <Text
          style={{
            color: "#8A8A8A",
          }}
        >
          Trip End
        </Text>{" "}
        : {endDate.toLocaleString()}
      </Text>
    </PDFCard>
  );
};

export default TripDate;

import { useQueryState } from "nuqs";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import OAuthButton from "../../../components/common/OAuthButton";
import { useUser } from "../../../hooks/useUser";
import BreakLine from "./_components/BreakLine";
import { Logo } from "../../../components/Logo";

export const SignInPage = () => {
  const { user, loading } = useUser();
  const [redirect, _] = useQueryState("redirect", {
    defaultValue: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) return navigate("/");
  }, [loading, user?.id]);

  return (
    <main className="bg-plain flex flex-col items-start justify-center h-full w-full px-10 gap-y-8">
      <div className="bg-primary w-[63px] h-[63px] rounded-full"></div>
      <div className="flex flex-col gap-y-3">
        <h1 className="text-title font-bold leading-7 text-black">
        เข้าสู่ระบบ เพื่อประสบการณ์
        </h1>
        <div className="flex items-center gap-x-2">
          <h1 className="text-title font-bold">แบบขั้นสุดกับ</h1>
          <Logo className="translate-y-[-2px]"/>
        </div>
      </div>
      <ul className="flex flex-col gap-y-5 w-full">
        <OAuthButton provider="google" redirectUrl={redirect} />
        {/* <OAuthButton provider="apple" /> */}
        {/* <OAuthButton provider="facebook" /> */}
      </ul>
      <BreakLine />
      <OAuthButton provider="email" redirectUrl={redirect} />
    </main>
  );
};

export default SignInPage;

import { Stack } from "@mui/material";
import React, { FC } from "react";
import Icon, { Icons } from "./Icon";

const MAXIMUM_RATING = 5;

type Props = {
  rating: number;
};

const Rating: FC<Props> = ({ rating }) => {
  if (rating > MAXIMUM_RATING) return "Invalid rating";
  return (
    <Stack direction={"row"} alignItems={"center"} gap={0.75}>
      <Icon name={Icons.starFilled} size={16} />
      <p className="font-semibold text-sm text-primary">{rating}</p>
    </Stack>
  );
};

export default Rating;

import { DB_TABLES, supabase } from "../../configs/db.config";
import { CityRequest } from "../../models/cityRequest.model";

export async function addCityRequest(
  request: Pick<CityRequest, "content" | "userId" | "email">
) {
  const { error } = await supabase.from(DB_TABLES.cityRequests).insert(request);

  if (error) {
    throw new Error(error.message);
  }
}

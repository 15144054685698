import { RefObject, useEffect } from "react";
import { RefCallBack } from "react-hook-form";

export function usePreventKeyboardPushingScreen(
  inputRef: RefObject<HTMLInputElement>
) {
  useEffect(() => {
    if (!inputRef) return;

    if (!inputRef.current) return;

    inputRef.current.onfocus = () => {
      window.scrollTo(0, 0);

      document.body.scrollTop = 0;
    };

    inputRef.current.onblur = () => {
      window.scrollTo(0, 0);

      document.body.scrollTop = 0;
    };
  }, []);
}

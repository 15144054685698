import { useMutation } from "@tanstack/react-query";
import { fetchGooglePlaces } from "../../helper/googlePlace.helper";

type Params = {
  query: string;
  country: string;
};

const QUERY_FIELDS = [
  "id",
  "displayName",
  "formattedAddress",
  "currentOpeningHours",
  "editorialSummary",
  "primaryType",
  "photos",
  "plusCode",
];

export function useSearchGooglePlaces({ query, country }: Params) {
  const mutation = useMutation({
    mutationKey: ["search google places", query, country],
    mutationFn: async () => {
      const destinations = fetchGooglePlaces(query, QUERY_FIELDS, country);
      return destinations;
    },
  });

  return mutation;
}

import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import React, { FC, useState } from "react";

type Props = {
  email: string;
  onRemoveEmail: (email: string) => void;
};

const TripMate: FC<Props> = ({ email, onRemoveEmail }) => {
  const [showUserConfirmation, setShowUserConfirmation] = useState(false);
  return (
    <Stack className="p-2" spacing={2}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={2}
      >
        <p className="font-bold text-black">{email}</p>
        {!showUserConfirmation && (
          <p
            onClick={() => setShowUserConfirmation(true)}
            className="text-sm text-red-500"
          >
            ลบ
          </p>
        )}
      </Stack>

      {showUserConfirmation && (
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <Button
              color="danger"
              fullWidth
              onClick={() => onRemoveEmail(email)}
            >
              ลบ
            </Button>
            <Button
              fullWidth
              className="text-black"
              onClick={() => setShowUserConfirmation(false)}
            >
              ยกเลิก
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default TripMate;

import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import classNames from "classnames";
import React, { FC } from "react";
import Icon, { Icons } from "../../../components/common/Icon";
import ItineraryPDF from "../../../components/specific/itinerary-pdf/ItineraryPDF";
import { CustomStyle } from "../../../models/component.model";
import { TripDetail } from "../../../models/trip.model";

type Props = {
  tripDetail: TripDetail;
};

const DEFAULT_PDF_FILE_NAME = "itinerary.pdf";

const PDFButton: FC<CustomStyle<Props>> = ({ className, tripDetail }) => {
  const buttonClassNames = classNames(
    "bg-transparent outline-none border-none focus:outline-none focus:border-none p-0",
    className
  );
  return (
    <PDFDownloadLink
      document={
        tripDetail ? (
          <ItineraryPDF itinerary={tripDetail} />
        ) : (
          <Document></Document>
        )
      }
      fileName={DEFAULT_PDF_FILE_NAME}
    >
      <button className={buttonClassNames}>
        <Icon name={Icons.pdf} />
      </button>
    </PDFDownloadLink>
  );
};

export default PDFButton;

import React, { FC } from "react";
import {
  addDaysForTripCardDate,
  formatTripCardDate,
} from "../../../libs/utils/date.util";

type Props = {
  startDate: string;
  duration: number;
  endDate: string;
};

const TripDateDetail: FC<Props> = ({ startDate, duration, endDate }) => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex items-center gap-x-1.5 text-[15px] text-black font-semibold">
        <p className="p-0 m-0">{formatTripCardDate(startDate)}</p>
        <p className="p-0 m-0">-</p>
        <p className="p-0 m-0">
          {
            endDate
            // addDaysForTripCardDate(
            //   itinerary.date,
            //   Number(itinerary.duration) - DAYS_OFFSET,
            //   "string"
            // ) as string
          }
        </p>
        <p className="p-0 m-0">{`(${
          duration !== 1 ? `${duration} วัน` : `${duration} วัน`
        })`}</p>
      </div>
    </div>
  );
};

export default TripDateDetail;

import { StyleSheet, View } from "@react-pdf/renderer";
import React, { FC, useMemo } from "react";
import { CustomPDFElementStyle, Parent } from "../../../models/component.model";
import HStack from "./HStack";
import VStack from "./VStack";

type Props = {
  axis?: "vertical" | "horizontal" | "none";
};

const PDFCard: FC<CustomPDFElementStyle<Parent & Props>> = ({
  axis = "horizontal",
  style,
  children,
}) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        width: "100%",
        fontSize: 12,
        padding: 15,
        borderRadius: 8,
        backgroundColor: "white",
        overflow: "hidden",
        ...style,
      },
    });
  }, []);
  return (
    <View style={styles.container}>
      {axis === "horizontal" && <HStack>{children}</HStack>}
      {axis === "vertical" && <VStack>{children}</VStack>}
      {axis === "none" && children}
    </View>
  );
};

export default PDFCard;

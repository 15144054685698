import { Stack } from "@mui/material";
import React from "react";

const BreakLine = () => {
  return (
    <Stack className="w-full items-center" direction={"row"} gap={1}>
      <div className="w-full h-[1px] bg-gray-300"></div>
      <p className="text-gray-300 text-[14px]">Or</p>
      <div className="w-full h-[1px] bg-gray-300"></div>
    </Stack>
  );
};

export default BreakLine;
